
import React, { Component } from 'react'; //different
import instance from "./axios-instance";
import Systems from "./SystemFunction";
import jwt_decode from "jwt-decode";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            activePage: 1,
            isActive: false,
            rewardlist: [],
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            point: 0,
            balance: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            token: window.localStorage.getItem("token"),
            type: "",
            amount: "",
            remark: "",
            name: "",
            password: "",
            linelink: "",
            page: "",
            left: false,
            changepassword: false,
        }
    }
    async componentDidMount() {

        const search = window.location.href;
        const params = new URLSearchParams(search);
        const space = search.split("/")
        this.setState({
            page: space[4].replace("#", ""),
            pathname: window.location.pathname.replace("/", "").trim()
        });
        try {
            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    this.setState({
                        linelink: datas[1].value
                    });
                }
                else {
                    // this.CheckThisGoalError();
                }
            });
        }
        catch (error) {
            //////console.log();
        }
    }

    render() {
        const pathname = this.state.pathname
        const { loginstate, page } = this.state
        return (
            <div>
                {(page === "" || page === "slot" || page === "arcade" || page === "fishing") ?
                    <div className="x-cover x-category-cover-container">

                        <div className="container-fluid h-100">
                            <div className="row -contents-wrapper">
                                <div className="col-12 col-md-6 -left-content">
                                    <div className="x-index-image-cover-inner-container">
                                        <img
                                            src="/build/web/ez-bet/img/ez-slot-cover-games-bg.png"
                                            className="-games-curve-bg"
                                            alt="Trixie168BET รูปพื้นหลัง games curve image png"
                                        />
                                        <img
                                            src="/build/web/ez-bet/img/ez-slot-cover-games-girl.png"
                                            className="-games-girls"
                                            alt="Trixie168BET รูปผู้หญิงน่ารัก games cute girl image png"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 -right-content">
                                    <h1 className="-title">
                                        Trixie168BET มีมากกว่า แต่ง่ายกว่า <br className="x-br-lg" />
                                        เกมเยอะ ภาพสวย <br className="x-br-lg" />
                                        ทั้งใหม่ และ เป็นที่นิยม
                                    </h1>
                                    <h2 className="-sub-title">เล่นได้ทั้ง มือถือ และ คอม แบบลื่นไหล</h2>
                                    <div
                                        className="-btn-wrapper d-none d-lg-inline-block"
                                        data-animatable="fadeInUp"
                                        data-delay={500}
                                    >
                                    </div>
                                </div>
                                <img
                                    src="/build/web/ez-bet/img/ez-slot-cover-coin.png"
                                    className="-games-girls-coin-customize -show js-cover-index-coin"
                                    alt="EZ Slot รูปเหรียญ games cute girl image png"
                                />
                                <img
                                    src="/build/web/ez-bet/img/ez-slot-cover-smile.png"
                                    className="-games-girls-smile-customize -show js-cover-index-smile"
                                    alt="EZ Slot รูปไอคอนยิ้ม games cute girl image png"
                                />
                            </div>
                        </div>
                        <div
                            className="-cover-foreground d-none d-md-block"
                            style={{
                                backgroundImage:
                                    'url("/build/web/ez-bet/img/ez-slot-cover-foreground.png")'
                            }}
                        />
                        <div
                            className="-cover-foreground -mini d-md-none"
                            style={{
                                backgroundImage:
                                    'url("/build/web/ez-bet/img/ez-slot-cover-foreground-mobile.png")'
                            }}
                        />
                    </div>
                    : ""
                }
                {
                    (page === "casino") ?
                        <div className="x-cover x-casino-cover-container">
                            <div className="container-fluid">
                                <div className="row -contents-wrapper">
                                    <div className="col-12 col-md-6 -left-content">
                                        <img src="/build/web/ez-bet/img/ez-slot-cover-casino-cartoon-1.png" className="-img" alt="Ez casino mascot image png" />
                                    </div>
                                    <div className="col-12 col-md-6 -right-content -anon">
                                        <div>
                                            <h1 className="-title">คาสิโนสดชั้นนำ<br />เล่นได้ทุกเกมส์ ไม่ต้องโยกเงิน</h1>
                                            <span className="-sub-title">ยินดีต้องรับสู่โลกแห่งคาสิโนที่จะทำให้คุณ<br />ลืมเว็บเก่าๆ
                                                ที่เคยเล่นมา</span>
                                            <img src="/build/web/ez-bet/img/ez-slot-ic-card-01-1.png" className="-img-card-1" alt="Ez live casino card png" />
                                            <img src="/build/web/ez-bet/img/ez-slot-ic-card-02-1.png" className="-img-card-2" alt="Ez live casino card png" />
                                            <img src="/build/web/ez-bet/img/ez-slot-ic-chip-01-1.png" className="-img-chip-1" alt="Ez live casino chip png" />
                                            <img src="/build/web/ez-bet/img/ez-slot-ic-chip-02-1.png" className="-img-chip-2" alt="Ez live casino chip png" />
                                        </div>
                                        {(loginstate) ? "" : <div className="-btn-wrapper x-btn-register d-none d-lg-inline-block" data-animatable="fadeInUp" data-delay={500}>
                                            <a href="#0" className="btn -register-btn txn" data-toggle="modal" data-target="#registerModal">
                                                <div className="-glow-container" />
                                                <span>สมัครสมาชิก</span>
                                                <div className="x-sonar-rectangle-center-animation" />
                                            </a>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="-cover-foreground d-none d-md-block" style={{ backgroundImage: 'url("/build/web/ez-bet/img/ez-slot-cover-foreground-1.png")' }} />
                            <div className="-cover-foreground -mini d-md-none" style={{ backgroundImage: 'url("/build/web/ez-bet/img/ez-slot-cover-foreground-mobile-1.png")' }} />
                        </div>
                        : ""}
                {
                    (page === "sport") ?
                        <div className="x-cover x-sport-cover-container -small-height">
                            <div className="container-fluid">
                                <div className="row -contents-wrapper">
                                    <div className="col-12 col-md-6 -left-content">
                                        <img src="/build/web/ez-bet/img/ez-slot-cover-sport-mobile-1.png" className="-img" alt="EZ" slot sport cover image png />
                                    </div>
                                    <div className="col-12 col-md-6 -right-content" >
                                        <div>
                                            <img src="/build/web/ez-bet/img/ez-slot-cover-sport-football-1.png" className="d-none d-md-block -img" alt="EZ" slot sport cover image png />
                                            <h1 className="-title">สปอร์ตชั้นนำ<br />เล่นได้ทุกเกมส์ ไม่ต้องโยกเงิน</h1>
                                            <span className="-sub-title">ยินดีต้องรับสู่โลกแห่งคาสิโนที่จะทำให้คุณ<br />ลืมเว็บเก่าๆ
                                                ที่เคยเล่นมา</span>
                                        </div>
                                        {(loginstate) ? "" : <div className="-btn-wrapper x-btn-register d-none d-lg-inline-block" data-animatable="fadeInUp" data-delay={500}>
                                            <a href="#0" className="btn -register-btn txn" data-toggle="modal" data-target="#registerModal">
                                                <div className="-glow-container" />
                                                <span>สมัครสมาชิก</span>
                                                <div className="x-sonar-rectangle-center-animation" />
                                            </a>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""}

                {
                    (page === "lotto") ?
                        <div className="x-cover x-lotto-cover-container">
                                       <div className="container-fluid h-100">
                            <div className="row -contents-wrapper">
                                <div className="col-12 col-md-6 -left-content">
                                    <div className="x-index-image-cover-inner-container">
                                        <img
                                            src="/build/web/ez-bet/img/ez-slot-cover-games-bg.png"
                                            className="-games-curve-bg"
                                            alt="Trixie168BET รูปพื้นหลัง games curve image png"
                                        />
                                        <img
                                            src="/build/web/ez-bet/img/ez-slot-cover-games-girl.png"
                                            className="-games-girls"
                                            alt="Trixie168BET รูปผู้หญิงน่ารัก games cute girl image png"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 -right-content">
                                    <h1 className="-title">
                                        Trixie168BET มีมากกว่า แต่ง่ายกว่า <br className="x-br-lg" />
                                        เกมเยอะ ภาพสวย <br className="x-br-lg" />
                                        ทั้งใหม่ และ เป็นที่นิยม
                                    </h1>
                                    <h2 className="-sub-title">เล่นได้ทั้ง มือถือ และ คอม แบบลื่นไหล</h2>
                                    <div
                                        className="-btn-wrapper d-none d-lg-inline-block"
                                        data-animatable="fadeInUp"
                                        data-delay={500}
                                    >
                                    </div>
                                </div>
                                <img
                                    src="/build/web/ez-bet/img/ez-slot-cover-coin.png"
                                    className="-games-girls-coin-customize -show js-cover-index-coin"
                                    alt="EZ Slot รูปเหรียญ games cute girl image png"
                                />
                                <img
                                    src="/build/web/ez-bet/img/ez-slot-cover-smile.png"
                                    className="-games-girls-smile-customize -show js-cover-index-smile"
                                    alt="EZ Slot รูปไอคอนยิ้ม games cute girl image png"
                                />
                            </div>
                        </div>
                        <div
                            className="-cover-foreground d-none d-md-block"
                            style={{
                                backgroundImage:
                                    'url("/build/web/ez-bet/img/ez-slot-cover-foreground.png")'
                            }}
                        />
                        <div
                            className="-cover-foreground -mini d-md-none"
                            style={{
                                backgroundImage:
                                    'url("/build/web/ez-bet/img/ez-slot-cover-foreground-mobile.png")'
                            }}
                        />
                        </div>
                        : ""}



            </div>
        );
    }
}

export default App;

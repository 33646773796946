import React, { Component } from 'react'; //different
//import './style.css';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import { json } from 'react-router-dom';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            stateopterror: "",
            stateregis: 1,
            username: null,
            userid: null,
            phoneNumber: "",
            userrecomend: "",
            nameInput: null,
            balance: 0,
            link: "",
            mainacc: null,
        }
        this.forgetotpandregister = this.forgetotpandregister.bind(this);
    }
    confirmdeposit = async (e, num) => {

        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;

        await instance.post("/api/v1/getredeempromotion", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    proderedeem: res.data.message.tblpromotionredeems,
                });

            }
        }).catch(async (err) => {
        });

        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                const { depositstate } = this.state
                ////////console.log(depositstate, num);
                if (num === 0) {
                    if (token != null) {
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            ////////console.log(res.data.picture)
                            this.setState({
                                mainacc: res.data.mainacc,
                                qrcode: res.data.picture
                            });
                        });
                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    forgetotpandregister = async (type) => {
        const { phoneNumber, otpphoneforget, otpphone } = this.state;
        var recheck = false;
        if (type === 4) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;
            });
        }
        else if (type === 5) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                pin: otpphoneforget,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;

            });
        }
        else if (type === 6) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;
            });
        }
        else if (type === 7) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                pin: otpphone,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;

            });
        }
        else if (type === 8) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                pin: otpphone,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;

            });
        }
        return recheck
    }

    nextstep = async (e, num) => {
        e.preventDefault();
        const { stateregis, phoneNumber, checkboxacc, fname, otpphone, accnumbertxt } = this.state


        if (num === 1) {
            console.log(num);
            const check = await this.forgetotpandregister(8);
            console.log(1, phoneNumber.length, check.checked);
            if (phoneNumber.length === 10 && check.checked) {
                this.setState({
                    stateregis: 2
                });
            }
            else {
                if (phoneNumber.length !== 10) {
                    this.setState({
                        stateopterror: "คุณใส่เบอร์ผิดพลาด"
                    });
                }
                else if (!check.checked && check.status === 201) {
                    this.setState({
                        stateopterror: "เบอร์นี้มีคนใช้แล้ว"
                    });
                }
                else {
                    this.setState({
                        stateopterror: "คุณทำรายการถี่เกินไป โปรดรอซักครู่"
                    });
                }

                document.getElementById("phoneNumber").style = "border-color: #ef4444;";
                document.getElementById("checkotpforgetphonenormal").style.display = "block";
            }
        }
        else if (num === 2) {

            if (checkboxacc !== 0 && fname !== "" && accnumbertxt !== "" && checkboxacc !== undefined) {
                this.setState({
                    stateregis: 3
                });
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'กรุณาเลือกธนาคาร หรือใส่ข้อมูลให้ครบถ้วน !!',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
                //////console.log(2, stateregis, checkboxacc);
            }
        }
        else if (num === 3) {
            //////console.log(1, stateregis, num);
            this.setState({
                stateregis: 4
            });
        }
        else if (num === 5) {
            var check = await this.forgetotpandregister(7);
            if (otpphone !== "" && check.checked) {
                this.setState({
                    stateregis: 2
                });
            }
            else if (otpphone === "" || otpphone.length < 4) {
                this.setState({
                    stateopterror: "คุณใส่ตัวเลขไม่ครบ"
                });
                document.getElementById("otpphone").style = "border-color: #ef4444;";
                document.getElementById("checkotp").style.display = "block";
            }

            else {
                this.setState({
                    stateopterror: "รหัสผิดพลาด"
                });
                document.getElementById("otpphone").style = "border-color: #ef4444;";
                document.getElementById("checkotp").style.display = "block";
            }



        }
        else if (num === 11) {
            //////console.log(1, stateregis, num);
            this.setState({
                stateregis: 1
            });
        }
        else if (num === 22) {
            //////console.log(1, stateregis, num);
            this.setState({
                stateregis: 2
            });
        }
    }

    onChangex = event => {
        console.log("event", event.target.name);


        if (event.target !== undefined) {
            let nam;
            let val;
            if (event.target.name === "otpphone") {
                document.getElementById("otpphone").style = "";
                document.getElementById("checkotp").style.display = "none";
            }
            else if (event.target.name === "phoneNumber") {
                // document.getElementById("phoneNumberforget").style = "";
                // document.getElementById("checkotpforgetphone").style.display = "none";
            }
            else if (event.target.name === "otpphoneforget") {
                document.getElementById("otpphoneforget").style = "";
                document.getElementById("checkotpforget").style.display = "none";
            }
            else if (event.target.name === "otpphoneforget") {
                document.getElementById("phoneNumber").style = "";
                document.getElementById("checkotpforgetphonenormal").style.display = "none";
            }

            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            this.setState({ [nam]: val });
        }
    };

    validatergisterForm = async (e) => {
        e.preventDefault();
        const { phoneNumber, fname, password, passwordcf, checkboxacc, accnumbertxt, link } = this.state;

        console.log(checkboxacc);
        //////////console.log(this.state)
        if (password.length < 6) {
            ////////console.log(password);
            var title = 'Error!'
            var text = 'Password คุณไม่ครบ 6 ตัวโปรดตรวจสอบ'
            var object = { title, text }
            this.modalerror(object);
            // this.CheckThisGoalError(object);
        }

        else if (phoneNumber === "") {

            title = 'Error!'
            text = 'คุณไม่ทำการใส่ เบอร์โทรศัพท์'
            object = { title, text }
            this.modalerror(object);

        }
        else if (accnumbertxt === "") {
            title = 'Error!'
            text = 'คุณไม่ทำการใส่เลขบัญชีธนาคาร'
            object = { title, text }
            this.modalerror(object);

        }
        else if (fname === "") {

            title = 'Error!'
            text = 'คุณไม่ทำการใส่ ชื่อ-นามสกุล'
            object = { title, text }
            this.modalerror(object);

        }
        else if (checkboxacc === 0) {
            title = 'Error!'
            text = 'คุณยังไม่ได้เลือกธนาคาร'
            object = { title, text }
            this.modalerror(object);
        }
        else if (password !== passwordcf) {
            title = 'Error!'
            text = 'คุณไส่พาสเวิดไม่ตรงกัน'
            object = { title, text }
            this.modalerror(object);
        }

        else {
            await instance.post("/api/v1/register", {
                User_id: phoneNumber,
                name: fname,
                password: password,
                accbank: checkboxacc,
                accno: accnumbertxt,
                System: Systems,
                link: link,
                Useradvise: this.state.userrecomend
            })
                .then(async (res) => {
                    if (res.data.status === 200) {

                        await Swal.fire({
                            title: 'Success!',
                            text: 'ทำการสมัครเรียบร้อย',
                            icon: 'success',
                            confirmButtonText: 'ตกลง'
                        })

                        await instance.post("/api/v1/userlogin", {
                            userid: phoneNumber,
                            password: password,
                            System: Systems,
                            Function: 'validateLoginForm-navbar.js'
                        }).then((res) => {
                            if (res.status === 200) {
                                if (res.data.status === 200) {
                                    localStorage.setItem('auth_token', res.data.token);
                                    window.location.href = '/'
                                }
                            }
                        });

                    }
                    else if (res.data.status === 202) {
                        await Swal.fire({
                            title: 'Error!',
                            text: 'Username นี้ทำการสมัครไปแล้ว',
                            icon: 'error',
                            confirmButtonText: 'ตกลง'
                        })
                        this.setState({
                            errorstatus: 1,
                            errortext: 'Username นี้ทำการสมัครไปแล้ว'
                        });
                    }
                    else {
                        await Swal.fire({
                            title: 'Error!',
                            text: 'Username หรือ ชื่อในการสมัครไม่ถูกต้อง',
                            icon: 'error',
                            confirmButtonText: 'ตกลง'
                        })
                        this.setState({
                            errorstatus: 1,
                            errortext: 'Username หรือ ชื่อในการสมัครไม่ถูกต้อง'
                        });
                    }
                });
        }


    };


    async saveclickaff(linkid) {
        await instance.post("/api/v1/saveclickaff", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
            if (res.data.status == 200) {
                var date = new Date()
                this.setState({
                    link: linkid,
                    userrecomend: res.data.username
                });
                var username = res.data.username
                var object = { date, username }
                localStorage.setItem('aff', JSON.stringify(object))
            }
        }).catch(async (err) => {
        });

    }
    async saveclick(linkid) {
        await instance.post("/api/v1/saveclick", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
            var date = new Date()
            this.setState({
                link: linkid,
                userrecomend: linkid
            });
            var username = linkid
            var object = { date, username }
            localStorage.setItem('register', JSON.stringify(object))
        });

    }
    async componentDidMount() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            localStorage.removeItem('auth_token', '')
        }

        const search = window.location.href;
        var test = search.split("aff=")[1]
        if (test != undefined) {
            this.saveclickaff(test.split("&")[0]);
        }
        var params = search.split("reg=")[1]
        if (params != undefined) {
            this.saveclick(params);
            this.setState({
                userrecomend: params.split("&")[0],
            });
        }


        var tokenf = localStorage.getItem('aff');
        if (tokenf != null) {
            var codev = JSON.parse(tokenf)
            //   console.log(codev);
            var Username = codev.username;
            this.setState({
                userrecomend: Username,
            });
        }

        var tokenx = localStorage.getItem('register');
        if (tokenx != null) {
            var codev = JSON.parse(tokenx)
            // console.log(codev);
            var Username = codev.username;
            this.setState({
                userrecomend: Username,
            });
        }


        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                var min = res.data.tblcatagoryx[0].limitdeposit;
                var max = res.data.tblcatagoryx[0].maxdeposit;
                var limit = { min, max }
                this.setState({
                    linelink: datas[1].value,
                    System: res.data.tblcatagoryx[0].System,
                    limit: limit
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        this.nameInput.focus();
    }





    render() {

        const { stateopterror, userrecomend } = this.state

        //console.log(prodetail.length)
        return (
            <div className="-full-flex-wrap ">
                <div className="x-cover x-index-promotion-container">
                    <div className="container-fluid">
                        <div className="row -contents-wrapper">
                            <div className="col-12 col-md-6 -left-content">
                                <div className="-snow-mascot-animation" data-animation-lottie="/build/web/ez-bet/animations/snowmascot.json" crossOrigin="anonymous" data-animatable="fadeInUp" data-delay="100"></div>
                            </div>
                            <div className="col-12 col-md-6 -right-content">
                                <div data-animatable="fadeInUp" data-delay={200}>
                                    <h1 className="-title">
                                        สมัครสมาชิก
                                    </h1>
                                    <h2 className="-sub-title">
                                        เกมดี โปรโมชั่นดี อันดับ 1 ของจริง ไม่ใช่ทุกคนที่ทำได้
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="-cover-foreground d-none d-md-block"
                        style={{
                            backgroundImage:
                                'url("/build/web/ez-bet/img/ez-slot-cover-foreground.png")'
                        }}
                    />
                    <div
                        className="-cover-foreground -mini d-md-none"
                        style={{
                            backgroundImage:
                                'url("/build/web/ez-bet/img/ez-slot-cover-foreground-mobile.png")'
                        }}
                    />
                </div>
                <div
                    className="-full-flex-inner-bg -bg"
                >
                    <div className="x-promotion-index">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="x-page-title-component ">
                                        <div className="-inner-wrapper">
                                            <h1 className="-title">สมัครสมาชิก</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="x-modal-separator-container ">
                                <div className="-top " style={{ display: this.state.stateregis === 1 ? "" : "none" }}>
                                    <h3 className="x-title-modal mx-auto text-center ">
                                        กรอกเบอร์โทรศัพท์
                                    </h3>
                                    <div className="-fake-inner-body">
                                        <div className="x-step-register -v2">
                                            <div className="px-0 m-auto -container-wrapper">
                                                <div className={"-step-box-outer" + (this.state.stateregis !== 1 ? "" : " step-active")}></div>
                                                <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                {/* <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div> */}
                                            </div>
                                        </div>
                                        <div >
                                            <div className="-x-input-icon mb-3 text-center">
                                                <img
                                                     src="/build/web/igame-index-lobby-wm/img/ic-input-phone.png"
                                                    className="-icon"
                                                    alt="SA Casino phone icon"
                                                    width={10}
                                                    height={14}
                                                />
                                                <input
                                                    onChange={this.onChangex}
                                                    type="number"
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    required="required"
                                                    pattern="[0-9]*"
                                                    className="x-form-control form-control"
                                                    placeholder="กรุณากรอกเบอร์โทรศัพท์"
                                                    autoFocus="autoFocus"
                                                    autoComplete="on"
                                                    inputMode="text"
                                                />
                                                <div id="checkotpforgetphonenormal" className="invalid-feedback ">
                                                    <ul className="list-unstyled mb-0"><li>{stateopterror}</li></ul>
                                                </div>
                                            </div>
                                            <h3 className="x-title-modal mx-auto text-center " style={{ marginBottom: "15px" }}>
                                                ผู้แนะนำ(ไม่ไส่ก็ได้)
                                            </h3>
                                            <div className="-x-input-icon mb-3 text-center">
                                                <img
                                                     src="/build/web/igame-index-lobby-wm/img/ic-user-v2.png"
                                                    className="-icon"
                                                    alt="SA Casino phone icon"
                                                    width={10}
                                                    height={14}
                                                />
                                                <input

                                                    onChange={this.onChangex}
                                                    readOnly={(userrecomend !== "") ? true : false}
                                                    style={{ color: (userrecomend !== "") ? "black" : "white" }}
                                                    type="text"
                                                    id="userrecomend"
                                                    name="userrecomend"
                                                    required="required"
                                                    value={userrecomend}
                                                    className="x-form-control form-control"
                                                    placeholder="ผู้แนะนำ"
                                                    autoFocus="autoFocus"
                                                    autoComplete="on"
                                                    inputMode="text"
                                                />
                                            </div>
                                            <div className="text-center">
                                                <button

                                                    ref={(input) => { this.nameInput = input; }}
                                                    onClick={(e) => {
                                                        this.nextstep(e, 1)
                                                    }}
                                                    type="submitx"
                                                    className="btn   btn-primary mt-lg-3 mt-0"
                                                    disabled=""
                                                >
                                                    ต่อไป
                                                </button>
                                            </div>
                                            <input

                                                type="hidden"
                                                id="request_otp__token"
                                                name="request_otp[_token]"
                                                defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="-top " style={{ display: this.state.stateregis === 5 ? "" : "none" }}>
                                    <h3 className="x-title-modal mx-auto text-center ">
                                        ยืนยัน OTP
                                    </h3>
                                    <div className="-img-container ">
                                        <picture>
                                            <img
                                                alt="กรอกรหัส OTP เพื่อยืนยันตัวตน"
                                                className="img-fluid -ic-otp"
                                                width={150}
                                                height={150}
                                                src="/build/web/ez-bet/img/register-icon-otp-v2.webp"
                                            />
                                        </picture>
                                    </div>

                                    <div className="-fake-inner-body">
                                        <div className="x-step-register -v2">
                                            <div className="px-0 m-auto -container-wrapper">
                                                <div className={"-step-box-outer" + (this.state.stateregis !== 5 ? "" : " step-active")}></div>
                                                <div className={"-step-box-outer" + (this.state.stateregis !== 5 ? "" : " step-active")}></div>
                                                <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                            </div>
                                        </div>
                                        <div >
                                            <div className="-x-input-icon mb-3 text-center">
                                                <img
                                                     src="/build/web/igame-index-lobby-wm/img/ic-input-phone.png"
                                                    className="-icon"
                                                    alt="SA Casino phone icon"
                                                    width={10}
                                                    height={14}
                                                />
                                                <input
                                                    onChange={this.onChangex}
                                                    type="text"
                                                    id="otpphone"
                                                    name="otpphone"
                                                    required="required"
                                                    pattern="[0-9]*"
                                                    maxLength="4"
                                                    className="x-form-control form-control"
                                                    placeholder="กรุณากรอก OTP 4 หลัก"
                                                    autoFocus="autoFocus"
                                                    autoComplete="on"
                                                    inputMode="text"
                                                />
                                                <div id="checkotp" className="invalid-feedback ">
                                                    <ul className="list-unstyled mb-0"><li>{stateopterror}</li></ul>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <a href={() => false} style={{ cursor: "pointer" }}
                                                    onClick={(e) => {
                                                        this.nextstep(e, 11)
                                                    }} className="text-muted mt-3">
                                                    ยกเลิก
                                                </a>
                                            </div>
                                            <div className="text-center">

                                                <button
                                                    onClick={(e) => {
                                                        this.nextstep(e, 5)
                                                    }}
                                                    type="submitx"
                                                    className="btn   btn-primary mt-lg-3 mt-0"
                                                    disabled=""
                                                >
                                                    ต่อไป
                                                </button>
                                            </div>
                                            <input
                                                type="hidden"
                                                id="request_otp__token"
                                                name="request_otp[_token]"
                                                defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="-top " style={{ display: this.state.stateregis === 2 ? "" : "none" }}>
                                    <h3 className="x-title-modal mx-auto text-center ">
                                        กรอกเลขที่บัญชี
                                    </h3>
                                    <div className="">
                                        <div
                                            data-animatable="fadeInModal"
                                            className="-animatable-container animated fadeInModal"
                                        >
                                            <div className="text-center -img-container">
                                                <div className="-title mt-3">กรุณาเลือกบัญชีธนาคารของท่าน</div>
                                            </div>
                                            <div className="x-step-register -v2">
                                                <div className="px-0 m-auto -container-wrapper">
                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                    <div className={"-step-box-outer" + (this.state.stateregis !== 1 ? "" : " step-active")}></div>
                                                </div>
                                            </div>
                                            <div id="card-bank-info">
                                                <div className="text-center">
                                                    <div className="my-3 js-bank-select-container">
                                                        <div className="x-bank-choices-type ">
                                                            <div className="-outer-wrapper" id='checkboxacc' onChange={this.onChangex}>
                                                                <input
                                                                    type="radio"
                                                                    className="-input-radio"
                                                                    id="bank-acc-51692356943"
                                                                    name="checkboxacc"
                                                                    defaultValue={5}
                                                                />
                                                                <label className="-label" htmlFor="bank-acc-51692356943">
                                                                    <img
                                                                        className="-logo"
                                                                        src="/media/cache/strip/201910/84c0f97b9a4a02bd519f6256da291d06.png"
                                                                        alt=""
                                                                    />
                                                                    <i className="fas fa-check" />
                                                                </label>
                                                                <input
                                                                    type="radio"
                                                                    className="-input-radio"
                                                                    id="bank-acc-61692356943"
                                                                    name="checkboxacc"
                                                                    defaultValue={6}
                                                                    data-gtm-form-interact-field-id={6}
                                                                />
                                                                <label className="-label" htmlFor="bank-acc-61692356943">
                                                                    <img
                                                                        className="-logo"
                                                                        src="/media/cache/strip/201910/75cf7484ae422a0d3897e82556cb04fd.png"
                                                                        alt=""
                                                                    />
                                                                    <i className="fas fa-check" />
                                                                </label>
                                                                <input
                                                                    type="radio"
                                                                    className="-input-radio"
                                                                    id="bank-acc-71692356943"
                                                                    name="checkboxacc"
                                                                    defaultValue={7}
                                                                />
                                                                <label className="-label" htmlFor="bank-acc-71692356943">
                                                                    <img
                                                                        className="-logo"
                                                                        src="/media/cache/strip/201910/ffcda61156271aa5d354b4d052590a14.png"
                                                                        alt=""
                                                                    />
                                                                    <i className="fas fa-check" />
                                                                </label>
                                                                <input
                                                                    type="radio"
                                                                    className="-input-radio"
                                                                    id="bank-acc-81692356943"
                                                                    name="checkboxacc"
                                                                    defaultValue={8}
                                                                />
                                                                <label className="-label" htmlFor="bank-acc-81692356943">
                                                                    <img
                                                                        className="-logo"
                                                                        src="/media/cache/strip/201910/4982427c6801f5e172ab1205a9166e2f.png"
                                                                        alt=""
                                                                    />
                                                                    <i className="fas fa-check" />
                                                                </label>
                                                                <input
                                                                    type="radio"
                                                                    className="-input-radio"
                                                                    id="bank-acc-91692356943"
                                                                    name="checkboxacc"
                                                                    defaultValue={9}
                                                                />
                                                                <label className="-label" htmlFor="bank-acc-91692356943">
                                                                    <img
                                                                        className="-logo"
                                                                        src="/media/cache/strip/201910/62cdaab59a028355f43825a426c41b94.png"
                                                                        alt=""
                                                                    />
                                                                    <i className="fas fa-check" />
                                                                </label>
                                                                <input
                                                                    type="radio"
                                                                    className="-input-radio"
                                                                    id="bank-acc-111692356943"
                                                                    name="checkboxacc"
                                                                    defaultValue={11}
                                                                />
                                                                <label className="-label" htmlFor="bank-acc-111692356943">
                                                                    <img
                                                                        className="-logo"
                                                                        src="/media/cache/strip/201910/6220cfdee8ca83b5533e6d5ac87b778b.png"
                                                                        alt=""
                                                                    />
                                                                    <i className="fas fa-check" />
                                                                </label>
                                                                <input
                                                                    type="radio"
                                                                    className="-input-radio"
                                                                    id="bank-acc-121692356943"
                                                                    name="checkboxacc"
                                                                    defaultValue={12}
                                                                />
                                                                <label className="-label" htmlFor="bank-acc-121692356943">
                                                                    <img
                                                                        className="-logo"
                                                                        src="/media/cache/strip/201910/a3671c1989ca4935f046b184b98b28d1.png"
                                                                        alt=""
                                                                    />
                                                                    <i className="fas fa-check" />
                                                                </label>
                                                                <input
                                                                    type="radio"
                                                                    className="-input-radio"
                                                                    id="bank-acc-141692356943"
                                                                    name="checkboxacc"
                                                                    defaultValue={14}
                                                                />
                                                                <label className="-label" htmlFor="bank-acc-141692356943">
                                                                    <img
                                                                        className="-logo"
                                                                        src="/media/cache/strip/201910/de6513eee5e2d90848de36a1be081ef5.png"
                                                                        alt=""
                                                                    />
                                                                    <i className="fas fa-check" />
                                                                </label>
                                                                <input
                                                                    type="radio"
                                                                    className="-input-radio"
                                                                    id="bank-acc-151692356943"
                                                                    name="checkboxacc"
                                                                    defaultValue={15}
                                                                />
                                                                <label className="-label" htmlFor="bank-acc-151692356943">
                                                                    <img
                                                                        className="-logo"
                                                                        src="/media/cache/strip/201910/4320a4686226cba7c35cfdbbe25a0e95.png"
                                                                        alt=""
                                                                    />
                                                                    <i className="fas fa-check" />
                                                                </label>
                                                                <input
                                                                    type="radio"
                                                                    className="-input-radio"
                                                                    id="bank-acc-161692356943"
                                                                    name="checkboxacc"
                                                                    defaultValue={16}
                                                                />
                                                                <label className="-label" htmlFor="bank-acc-161692356943">
                                                                    <img
                                                                        className="-logo"
                                                                        src="/media/cache/strip/201910/48e5e7cc029492341f34b7ce38926edd.png"
                                                                        alt=""
                                                                    />
                                                                    <i className="fas fa-check" />
                                                                </label>
                                                                <input
                                                                    type="radio"
                                                                    className="-input-radio"
                                                                    id="bank-acc-171692356943"
                                                                    name="checkboxacc"
                                                                    defaultValue={17}
                                                                />
                                                                <label className="-label" htmlFor="bank-acc-171692356943">
                                                                    <img
                                                                        className="-logo"
                                                                        src="/media/cache/strip/201910/a68c3e5add8754e2c0e986c408c08c24.png"
                                                                        alt=""
                                                                    />
                                                                    <i className="fas fa-check" />
                                                                </label>
                                                                <input
                                                                    type="radio"
                                                                    className="-input-radio"
                                                                    id="bank-acc-991692356943"
                                                                    name="checkboxacc"
                                                                    defaultValue={99}
                                                                />
                                                                <label className="-label" htmlFor="bank-acc-991692356943">
                                                                    <img
                                                                        className="-logo"
                                                                        src="https://yt3.googleusercontent.com/aWp38-LVqL_fztxB3VkBaYHj6otE3dmCq-QMhEJUdRvDQbn1xU5gvU9CrB15x6-aWbLA9oQ1CQ=s900-c-k-c0x00ffffff-no-rj"
                                                                        alt=""
                                                                    />
                                                                    <i className="fas fa-check" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="js-bank-number-and-name-container position-relative">
                                                        <div className="x-bank-info-validate-box -v2" style={{ color: "black" }} >
                                                            <div className="-content-validate">
                                                                โปรดตรวจสอบ ชื่อ และ เลขบัญชี !!
                                                            </div>
                                                            <div className="-detail-validate">
                                                                หากไม่ถูกต้อง จะไม่สามารถแก้ไข หรือ ฝากถอนได้
                                                            </div>
                                                            <i className="fas fa-arrow-right" />
                                                        </div>
                                                        <div className="">
                                                            <img
                                                                className="-icon"
                                                                alt="กระเป๋าเงิน ฝากถอน ออโต้"
                                                                width={15}
                                                                height={15}
                                                                 src="/build/web/igame-index-lobby-wm/img/ic-library-book-v2.png?v=5"
                                                            />
                                                            <input
                                                                type="number"
                                                                onChange={this.onChangex}
                                                                id="accnumbertxt"
                                                                name="accnumbertxt"
                                                                required="required"
                                                                pattern="[0-9]*"
                                                                className="x-form-control form-control"
                                                                placeholder="กรอกเลขบัญชี"
                                                                autoFocus="autoFocus"
                                                                inputMode="text"
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <img
                                                                className="-icon"
                                                                alt="รองรับการเข้าใช้งานหลายผู้เล่น 24 ชั่วโมง"
                                                                width={15}
                                                                height={15}
                                                                 src="/build/web/igame-index-lobby-wm/img/ic-user-v2.png?v=5"
                                                            />
                                                            <input
                                                                type="text"
                                                                onChange={this.onChangex}
                                                                id="fname"
                                                                name="fname"
                                                                required="required"
                                                                className="x-form-control form-control"
                                                                placeholder="กรอก ชื่อ - นามสกุล (ภาษาไทยเท่านั้น)"
                                                            />
                                                        </div>
                                                        <div className="-text-hint col-sm-10 m-auto d-block d-lg-none">
                                                            กรอกข้อมูลให้ครบถ้วนและถูกต้องมิฉะนั้นท่านจะไม่สามารถถอนเงินได้
                                                        </div>
                                                    </div>
                                                    <a href={() => false} style={{ cursor: "pointer" }}
                                                        onClick={(e) => {
                                                            this.nextstep(e, 11)
                                                        }} className="text-muted mt-3">
                                                        ยกเลิก
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <input
                                            type="hidden"
                                            id="customer_bank_account__token"
                                            name="customer_bank_account[_token]"
                                            defaultValue="EtuWE7npKiT8yTfHa4RNu7uFMEY1cTFVfBKaSda8kGc"
                                        />

                                    </div>
                                    <div className="text-center">
                                        <button
                                            onClick={(e) => {
                                                this.nextstep(e, 2)
                                            }}
                                            type="submitxa"
                                            className="btn   btn-primary mt-lg-3 mt-0"
                                            disabled=""
                                        >
                                            ต่อไป
                                        </button>
                                    </div>
                                    <input
                                        type="hidden"
                                        id="request_otp__token"
                                        name="request_otp[_token]"
                                        defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                    />
                                </div>
                                <div className="-top " style={{ display: this.state.stateregis === 3 ? "" : "none" }}>
                                    <h3 className="x-title-modal mx-auto text-center ">
                                        กรุณา Confirm Password
                                    </h3>
                                    <div className="-fake-inner-body">
                                        <div className="x-step-register -v2">
                                            <div className="px-0 m-auto -container-wrapper">
                                                <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                            </div>
                                        </div>
                                        <div >
                                            <div className="text-center">
                                                <div className="-x-input-icon mb-3 text-center">
                                                    <img
                                                         src="/build/web/igame-index-lobby-wm/img/ic-input-lock.png"
                                                        className="-icon"
                                                        alt="SA Casino phone icon"
                                                        width={10}
                                                        height={14}
                                                    />
                                                    <input
                                                        onChange={this.onChangex}
                                                        type="password"
                                                        id="password"
                                                        name="password"
                                                        required="required"
                                                        className="x-form-control form-control"
                                                        placeholder="กรุณารหัสผ่าน"
                                                        autoFocus="autoFocus"
                                                        autoComplete="on"
                                                        inputMode="text"
                                                    />
                                                </div>
                                                <div className="-x-input-icon mb-3 text-center">
                                                    <img
                                                         src="/build/web/igame-index-lobby-wm/img/ic-input-lock.png"
                                                        className="-icon"
                                                        alt="SA Casino phone icon"
                                                        width={10}
                                                        height={14}
                                                    />
                                                    <input
                                                        onChange={this.onChangex}
                                                        type="password"
                                                        id="passwordcf"
                                                        name="passwordcf"
                                                        required="required"
                                                        className="x-form-control form-control"
                                                        placeholder="กรุณารหัสผ่าน"
                                                        autoFocus="autoFocus"
                                                        autoComplete="on"
                                                        inputMode="text"
                                                    />
                                                    <div className="mt-3 -spacer  text-center" style={{ display: (this.state.errorstatus ? "" : "none") }}>
                                                        <div className="x-danger-zone -v2  text-center" style={{ color: "red" }}>
                                                            <span>{this.state.errortext}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a href={() => false} style={{ cursor: "pointer", marginTop: "20px", marginRight: "10px" }}
                                                    onClick={(e) => {
                                                        this.nextstep(e, 22)
                                                    }} className="text-muted mt-3">
                                                    ยกเลิก
                                                </a>
                                                <button
                                                    onClick={(e) => {
                                                        this.validatergisterForm(e)
                                                    }}
                                                    type="submitx"
                                                    className="btn btn-primary mt-lg-3 mt-0 -submit"
                                                    disabled=""
                                                >
                                                    ยืนยัน
                                                </button>
                                            </div>
                                            <input
                                                type="hidden"
                                                id="request_otp__token"
                                                name="request_otp[_token]"
                                                defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="-bottom ">
                                    <div className="x-admin-contact text-center ">
                                        <span className="x-text-with-link-component">
                                            <a

                                                href={this.state.linelink}
                                                className="-link-message "
                                                style={{ cursor: "pointer" }}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            > <label style={{ cursor: "pointer" }} className="-text-message ">ติดต่อฝ่ายบริการลูกค้า</label>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default App;

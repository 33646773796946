import React, { Component } from 'react'; //different
//import './style.css';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            username: null,
            userid: null,
            phoneNumber: "",
            balance: 0,
            mainacc: null,
            qrcode: null,
            stateregis: 1,
            checkboxacc: 0,
            loginstate: false,
            fullname: null,
            lname: null,
            alert: null,
            depositstate: 0,
            depositamount: 0,
            token: window.localStorage.getItem("token"),
            accnumber: null,
            accnumbertxt: null,
            withdrawamount: 0,
            rankpoint: 0,
            changepassword: false,
            linelink: "",
            errortext: "",
            affpercent: 0,
            userrecomend: "",
            historylist: [],
            useradvice: [],
            tblnextrank: [],
            tblrankall: [],
            tblprofile: [],
            prodetail: [],
            prolist: [],
            prolistx: [],
            linkaff: "",
            picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
            proderedeem: [],
            buttoncliam: false,
            limit: null,
            rankpic: null,
            rankname: null,
            rankob: null,
            termAndCondition: false,
            errorstatus: false,
        }
    }
    confirmdeposit = async (e, num) => {

        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;

        await instance.post("/api/v1/getredeempromotion", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    proderedeem: res.data.message.tblpromotionredeems,
                });

            }
        }).catch(async (err) => {
        });

        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                const { depositstate } = this.state
                ////////console.log(depositstate, num);
                if (num === 0) {
                    if (token != null) {
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            ////////console.log(res.data.picture)
                            this.setState({
                                mainacc: res.data.mainacc,
                                qrcode: res.data.picture
                            });
                        });
                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    componentDidMount() {
        this.promotionlist();
        // var token = localStorage.getItem('auth_token');
        // if (token != null) {
        //     var decoded = jwt_decode(token);
        //     var Username = decoded.message.playerid;
        //     this.setState({
        //         username: Username,
        //     });


        // }
    }

    redeempro = async (e, proid) => {
        e.preventDefault();

        // var token = localStorage.getItem('auth_token');
        // var decoded = jwt_decode(token);
        // var Username = decoded.message.playerid;
        // //console.log(Username, proid);
        // if (token != null) {
        //     this.setState({
        //         username: Username,
        //     });
        //     await instance.post("/api/v1/redeempromotion", {
        //         userid: Username,
        //         System: Systems,
        //         Proid: proid,
        //     }, {
        //         headers: { Authorization: `Bearer ${token}` }
        //     }).then(async (res) => {
        //         //console.log(res.data.status)
        //         if (res.data.status === 200) {
        //             document.getElementById("opendepositsuper").click()
        //         }
        //         else if (res.data.status === 201) {
        //             await Swal.fire({
        //                 title: 'error!',
        //                 text: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
        //                 icon: 'error',
        //                 confirmButtonText: 'ตกลง'
        //             })
        //             e.preventDefault();
        //             return false;
        //         }
        //         else if (res.data.status === 204) {
        //             await Swal.fire({
        //                 title: 'error!',
        //                 text: 'ท่านใช้สิทธวันนี้ครบแล้ว',
        //                 icon: 'error',
        //                 confirmButtonText: 'ตกลง'
        //             })
        //             e.preventDefault();
        //             return false;
        //         }
        //         else if (res.data.status === 202) {
        //             await Swal.fire({
        //                 title: 'error!',
        //                 text: 'ท่านใช้โปรมั่นชั่นนี้ไปแล้ว',
        //                 icon: 'error',
        //                 confirmButtonText: 'ตกลง'
        //             })
        //             e.preventDefault();
        //             return false;
        //         }

        //     }).catch(async (err) => {
        //         // console.log(err);
        //         if (err.response.status === 401 || err.response.status === 403) {
        //             localStorage.clear();
        //             await Swal.fire({
        //                 title: 'Warning!',
        //                 text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
        //                 icon: 'warning',
        //                 confirmButtonText: 'ตกลง'
        //             })
        //             window.location.href = '/';
        //         }
        //     });
        // }
    }

    checkpro = async (e, proid) => {

        window.location.href = "blog/blog" + proid;
        // var Username = "";
        // var token = localStorage.getItem('auth_token');
        // if (token != null) {
        //     var decoded = jwt_decode(token);
        //     Username = decoded.message.playerid;
        // }
        // await instance.post("/api/v1/checkpromotion", {
        //     userid: Username,
        //     System: Systems,
        //     Proid: proid,
        // }, {
        //     headers: { Authorization: `Bearer ${token}` }
        // }).then(async (res) => {
        //     if (res.data.status === 200) {
        //         //console.log(res.data);
        //         if (res.data.type === 0) {
        //             this.setState({
        //                 prodetail: res.data.message.tblpromotions[0],
        //             });
        //         }
        //         else if (res.data.type === 1) {
        //             this.setState({
        //                 proderedeem: res.data.message.tblpromotionredeems,
        //             });
        //         }

        //     }
        //     else if (res.data.status === 201) {
        //         this.setState({
        //             prodetail: [],
        //             picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
        //         });

        //     }
        //     else if (res.data.status === 202) {
        //         await Swal.fire({
        //             title: 'error!',
        //             text: 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย',
        //             icon: 'error',
        //             confirmButtonText: 'ตกลง'
        //         })
        //         return false;
        //     }

        // }).catch(async (err) => {
        //     //   console.log(err);
        //     if (err.response.status === 401 || err.response.status === 403) {
        //         localStorage.clear();
        //         await Swal.fire({
        //             title: 'Warning!',
        //             text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
        //             icon: 'warning',
        //             confirmButtonText: 'ตกลง'
        //         })
        //         window.location.href = '/';
        //     }
        // });
    }
    promotionlist = async () => {

        await instance.post("/api/v1/getblog", {
            System: Systems,
            Type: 1
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    prolistx: res.data.message,
                });
            }
        }).catch(async (err) => {
            console.log(288, err);

            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await Swal.fire({
                    title: 'Warning!',
                    text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
                window.location.href = '/';
            }
        });
    }

    render() {

        const { prodetail, picnonpro, username, prolistx } = this.state
        console.log(306, prolistx)
        return (
            <div className="-full-flex-wrap ">
                <div className="x-cover x-index-promotion-container">
                    <div className="container-fluid">
                        <div className="row -contents-wrapper">
                            <div className="col-12 col-md-6 -left-content">
                                <div className="-snow-mascot-animation" data-animation-lottie="/build/web/ez-bet/animations/snowmascot.json" crossOrigin="anonymous" data-animatable="fadeInUp" data-delay="100"></div>
                            </div>
                            <div className="col-12 col-md-6 -right-content">
                                <div data-animatable="fadeInUp" data-delay={200}>
                                    <h1 className="-title">
                                        โปรแรงเวอร์ กิจกรรมเพียบ
                                        <br />
                                        จัดหนัก แรงสุดในไทย
                                    </h1>
                                    <h2 className="-sub-title">
                                        เกมดี โปรโมชั่นดี อันดับ 1 ของจริง ไม่ใช่ทุกคนที่ทำได้
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="-cover-foreground d-none d-md-block"
                        style={{
                            backgroundImage:
                                'url("/build/web/ez-bet/img/ez-slot-cover-foreground.png")'
                        }}
                    />
                    <div
                        className="-cover-foreground -mini d-md-none"
                        style={{
                            backgroundImage:
                                'url("/build/web/ez-bet/img/ez-slot-cover-foreground-mobile.png")'
                        }}
                    />
                </div>
                <div className="x-promotion-index">

                    <div style={{textAlign:"center"}}>
                        <span style={{ color: 'rgb(35,8,8)', fontSize: '50px', fontFamily: 'Kanit' }}>บทความ</span>
                    </div>
                    <div className="container">
                        <div className="row px-2">
                            {prolistx.map(prolists => (
                                <React.Fragment key={prolists.no}>
                                    <div
                                        className="col-lg-4 col-6 -promotion-card-link"
                                    >
                                        <a
                                            href="#0"
                                            className="d-block h-100 "
                                            data-force="true"
                                            data-toggle="modal"
                                            onClick={(e) => this.checkpro(e, prolists.id)}
                                            data-target="#ProDetailModalsuper"
                                        >
                                            <div className="x-card card -multi-card">
                                                <div className="-img-container">
                                                    <img
                                                        style={{ display: (prolists.HeaderPic !== null) ? "" : "none" }}
                                                        src={prolists.HeaderPic}
                                                        alt="300 ขึ้นไป เพิ่มให้ 100"
                                                        className="-img"
                                                    />
                                                </div>
                                                <div className="card-body">
                                                    <div className="-title-container m-3">
                                                        <h2 className="-title"></h2>
                                                        <h3 className="-sub-title">{prolists.Headername}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                </React.Fragment>

                            ))}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default App;

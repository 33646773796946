import React, { Component } from 'react'; //different
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
//import './style.css';
import Main from './main';
import Casino from './casino';
import NavMenu from './navmenu';
import Slot from './slot';
import Arcade from './arcade';
import Sport from './sport';
import Fising from './fising';
import Promotion from './promotion';
import Login from './login';
import Logout from './logout';
import Linklistall from './linklistall';
import Linklistalldemo from './linklistaldemo';
import instance from "./axios-instance";
import Systems from "./SystemFunction";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import Freegame from './slotfree';
import Checkin from './checkin';
import Register from './register';
import moment from 'moment';
import Logintoken from './logintoken';
import Article from './article';
import Blog from './blog';
import io from "socket.io-client";
import Ranking from './ranking';
import { isMobile } from 'react-device-detect';
import $ from "jquery";
import Paoyingchub from './game/paoyingchub';
import "@fontsource/kanit";

import md5 from 'md5';
import Spinwheels from './spinweel';
import { colors } from '@mui/material';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {

      mainsetting: [],
      rewardlist: [],
      casinolist: [],
      fishinglist: [],
      sportlist: [],
      slotlist: [],
      gamelistall: [],
      activePage: 1,
      checkboxcookie: false,
      isActive: false,
      formData: {}, // Contains login form data
      errors: {}, // Contains login field errors
      formSubmitted: false, // Indicates submit status of login form
      loading: true, // Indicates in progress state of login form
      pagecount: 1,
      username: null,
      settingwheel: "",
      settingcheckin: "",
      userid: null,
      phoneNumber: "",
      balance: 0,
      mainacc: null,
      qrcode: null,
      stateregis: 1,
      checkboxacc: 0,
      products_page_count: 1,
      loginstate: false,
      fullname: null,
      lname: null,
      link: "",
      alert: null,
      depositstate: 0,
      stateforget: 1,
      depositamount: 50,
      stateopterror: "",
      btnisActive: true,
      limitwithdraw: { minw: 0, maxw: 0 },
      otpphone: "",
      token: window.localStorage.getItem("token"),
      accnumber: null,
      accnumbertxt: null,
      withdrawamount: 0,
      rankpoint: 0,
      changepassword: false,
      linelink: "",
      errortext: "",
      trueaccount: null,
      datas: null,
      otpphoneforget: "",
      affpercent: 0,
      userrecomend: "",
      historylist: [],
      useradvice: [],
      cashoutlist: [],
      tblnextrank: [],
      prolistx: [],
      tblrankall: [],
      maxwithdrawstatus: 0,
      tblprofile: [],
      prodetail: [],
      oldpassword: "",
      linkaff: "",
      webnamex: "",
      inviteSet: "",
      truegiftlink: "",
      proderedeem: null,
      proderedeemlist: [],
      buttoncliam: false,
      limit: null,
      rankpic: "1.jpeg",
      rankname: null,
      rankob: null,
      cashoutSet: [],
      tblPopupMenu1: [],
      tblPopupMenu2: [],
      tblPopupMenu: null,
      termAndCondition: false,
      errorstatus: false,
    }
    this.handleClick = this.handleClick.bind(this);
    this.forgetotpandregister = this.forgetotpandregister.bind(this);
    //  this.componentDidMount = this.componentDidMount.bind(this);
  }
  copyCode = () => {
    navigator.clipboard
      .writeText(this.state.linkaff)
      .then(() => {
        setTimeout(function () {
        }, 3000);
      })
      .catch((err) => {
        // console.log(err.message);
      });
  };
  logout = async (e) => {
    await localStorage.removeItem('auth_token', null)
    window.location.href = 'https://trixie168.com/';
  }
  opengame(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabgame");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinkgame");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    this.setState({
      gamelistall: []
    });
    document.getElementById("gamelist").style.display = "block";
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }


  async opengamelist(evt, cityName, gametype, partner) {

    console.log(163, evt, cityName, gametype, partner)
    await this.checkonline(gametype, partner);
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabgame");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("listname");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    document.getElementById(cityName).style.display = "block";

    //evt.currentTarget.className += " active";
  }
  async checkonline(typepage, partner) {

    this.setState({
      typepage: typepage,
    });
    instance.post("/api/v1/tabgamelist", {
      state: "999",
      System: Systems,
      partner: partner,
      game: typepage,
    })
      .then((res) => {
        // console.log(213,res);
        if (res.data.status === 200) {
          //  console.log(213,res.data);
          this.setState({
            gamelistall: res.data.message
          });
        }
        else {
          //  this.CheckThisGoalError();
        }
      });

    this.setState({
      typepage: typepage,
    });
  }

  async getcasinolist() {
    try {

      await instance.post("/api/v1/tabgamelist", {
        state: "2",
        System: Systems,
      })
        .then((res) => {
          if (res.data.status === 200) {
            this.setState({
              casinolist: res.data.message
            });
          }
          else {
          }
        });
    }
    catch (ex) {
      // console.log(ex);
    }

  }
  async getslotlist() {
    try {

      await instance.post("/api/v1/tabgamelist", {
        state: "3",
        System: Systems,
      })
        .then((res) => {
          if (res.data.status === 200) {
            this.setState({
              slotlist: res.data.message
            });
          }
          else {
          }
        });
    }
    catch (ex) {
      // console.log(ex);
    }

  }
  async getsportlist() {
    try {

      await instance.post("/api/v1/tabgamelist", {
        state: "5",
        System: Systems,
      })
        .then((res) => {
          if (res.data.status === 200) {
            this.setState({
              sportlist: res.data.message
            });
          }
          else {
          }
        });
    }
    catch (ex) {
      // console.log(ex);
    }

  }
  async getsfishinglist() {
    try {

      await instance.post("/api/v1/tabgamelist", {
        state: "8",
        System: Systems,
      })
        .then((res) => {
          if (res.data.status === 200) {
            this.setState({
              fishinglist: res.data.message
            });
          }
          else {
          }
        });
    }
    catch (ex) {
      // console.log(ex);
    }

  }

  promotionlist = () => {
    instance.post("/api/v1/getpromotion", {
      System: Systems,
    }).then(async (res) => {
      if (res.data.status === 200) {
        this.setState({
          prolistx: res.data.message,
        });
      }
    }).catch(async (err) => {
      //   console.log(err);

      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });
  }
  opentab(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";

    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");

    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";



    $("#backtohistory").hide();
    if ($("#slip").css('display') == 'block') {
      $("#backtohistory").show();
    } else {
      $("#backtohistory").hide();
    }




    if ($("#section01").css('display') == 'block') {
      $("#containbacktohome").hide();
    } else {
      $("#containbacktohome").show();
    }



  }

  openfriendtab(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("friendcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";

    }
    tablinks = document.getElementsByClassName("ininwrapgrid001");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");

    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";




    if ($("#allfriend").css('display') == 'none') {
      $(".countearnmoney").hide();
      $(".pcfriendback").hide();
    } else {
      $(".countearnmoney").show();
      $(".pcfriendback").show();
    }


    if ($("#withdrawfriendtabs").css('display') == 'block') {
      $("#withdrawfriend").show();
    } else {
      $("#withdrawfriend").hide();
    }



  }

  redeemlink = (e, link) => {
    e.preventDefault();
    //this.checklogin();
    console.log('tag', this.state.loginstate);
    if (!this.state.loginstate) {
      var title = 'error'
      var text = ' คุณไม่ได้ทำการ Login'
      var object = { title, text }
      this.modalerror(object);
    }
    else {
      window.location.href = link;
    }
  }

  getpopup = async () => {
    //  var token = localStorage.getItem('auth_token');
    //  if()
    // var decoded = jwt_decode(token);
    //  var Username = decoded.message.playerid;
    await instance.post("/api/v1/popuplist", {
      System: Systems,
      Cat_ID: 0,
      page: 1,
      type: 1
    }).then(async (res) => {
      if (res.data.status === 200) {
        console.log(160, res.data.message.banklist);
        this.setState({
          tblPopupMenu1: res.data.message.tblPopupMenu1,
          tblPopupMenu: res.data.message.banklist,
          tblPopupMenu2: res.data.message.tblPopupMenu2,
        });



      }
    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });


    const search = window.location.href;
    if (search.split("/").length <= 4) {
      if (!this.state.checkboxcookie) {
        // document.getElementById("openpopup").click()
      }
    }

  }

  openAccoutx(evt, cityName) {
    //  console.log(21326356);
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].className += " -open";
    }
  }

  async opencliamcashout(evt, cityName) {
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    await instance.post("/api/v1/cliamcashout", {
      userid: Username,
      System: Systems,
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(async (res) => {
      if (res.data.status === 200) {
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        this.rechecklogin(Username, token);
        await Swal.fire({
          title: 'Success!',
          text: 'ทำรายการรับเงินเรียบร้อย',
          icon: 'success',
          confirmButtonText: 'ตกลง'
        })

      }
      else if (res.data.status === 201) {
        await Swal.fire({
          title: 'error!',
          text: 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }
      else if (res.data.status === 202) {
        await Swal.fire({
          title: 'error!',
          text: 'ไม่สามารถทำรายการได้',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }

    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });
  }

  couponredeem = async (e, index) => {
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      await instance.post("/api/v1/usercoupon", {
        User_id: Username,
        System: Systems,
        couponkey: this.state.couponkey,
        type: 1
      }).then(async (res) => {
        if (res.data.status === 200) {
          const datas = res.data.message;
          await Swal.fire({
            title: 'Success!',
            text: 'ดำเนินการใช้คูปองเรียบร้อย',
            icon: 'success',
            confirmButtonText: 'ตกลง'
          })
        }
        else {
          await Swal.fire({
            title: 'Warning!',
            text: 'ไม่มี Key นี้ในระบบ',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
        }
      });
    }

  }
  redeem = async (e, gameIdx, partner) => {
    console.log(55555, partner, gameIdx, this.state.username, 77);
    var userAgent = navigator.userAgent
    console.log(userAgent);
    await this.checklogin();
    if (this.state.username !== null) {
      if (partner !== null) {
        Swal.fire({
          position: "center",
          text: 'กำลังเปิดเกม รอซักครู่',
          icon: 'success',
          showConfirmButton: false,
          timer: 800
        });
        await instance.post("/api/v1/tablauncher", {
          gameId: gameIdx,
          partner: partner,
          System: Systems,
          playerUsername: this.state.username,
          isMobile: isMobile,
          demo: false
        })
          .then((res) => {
            if (res.data.status === 200) {
              //////console.log(res.data.message);
              var test = res.data.message.launcher
              if (test !== undefined)
                if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                  window.location.href = test.mobile;
                }
                else {

                  if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                  else window.open(test.mobile);
                }
              else if (test === undefined) {
                Swal.fire({
                  title: 'error!',
                  text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                  icon: 'error',
                  confirmButtonText: 'ตกลง'
                })
              }
            }
            else {
              Swal.fire({
                title: 'error!',
                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                icon: 'error',
                confirmButtonText: 'ตกลง'
              })
              //  this.CheckThisGoalError();
            }
          });
      }
    }


  }

  async opencliaminvite(evt, cityName) {
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    await instance.post("/api/v1/cliaminvite", {
      userid: Username,
      System: Systems,
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(async (res) => {
      if (res.data.status === 200) {
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        this.rechecklogin(Username, token);
        await Swal.fire({
          title: 'Success!',
          text: 'ทำรายการรับเงินเรียบร้อย',
          icon: 'success',
          confirmButtonText: 'ตกลง'
        })

      }
      else if (res.data.status === 201) {
        await Swal.fire({
          title: 'error!',
          text: 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }
      else if (res.data.status === 202) {
        await Swal.fire({
          title: 'error!',
          text: 'ไม่สามารถทำรายการได้',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }

    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });
  }

  openAccout(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("js-profile-account-modal");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tabaccount");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }

  handleClick = async (e, price) => {
    if (price <= this.state.balance) {
      this.setState({
        withdrawamount: price
      });
    }
    else {
      await Swal.fire({
        title: 'Warning!',
        text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      })
    }
  }

  checkpro = async (e, proid) => {
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    await instance.post("/api/v1/checkpromotion", {
      userid: Username,
      System: Systems,
      Proid: proid,
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(async (res) => {
      if (res.data.status === 200) {
        console.log(209, res.data);
        if (res.data.type === 0) {
          this.setState({
            prodetail: res.data.message.tblpromotions[0],
          });
        }
        else if (res.data.type === 1) {
          this.setState({
            //proderedeem: res.data.message.tblpromotionredeems,
            proderedeemlist: res.data.message.tblpromotionredeems,


          });
        }

      }
      else if (res.data.status === 201) {
        this.setState({
          prodetail: [],
          picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
        });

      }
      else if (res.data.status === 202) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }

    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });

    await instance.post("/api/v1/getredeempromotion", {
      userid: Username,
      System: Systems,
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(async (res) => {
      console.log(799, res);
      if (res.data.status === 200) {
        this.setState({
          proderedeem: res.data.message.tblpromotionredeems,
        });

      }
    }).catch(async (err) => {
    });

  }

  redeempro = async (e, proid) => {
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    // console.log(Username, proid);
    await instance.post("/api/v1/redeempromotion", {
      userid: Username,
      System: Systems,
      Proid: proid,
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(async (res) => {
      //  console.log(res.data.status)
      if (res.data.status === 200) {
        //  document.getElementById("opendeposit").click()

        this.confirmdeposit(null, 0);
        await Swal.fire({
          title: 'success!',
          text: 'รับโปรโมชั่นเรียบร้อย',
          icon: 'success',
          confirmButtonText: 'ตกลง'
        })
      }
      else if (res.data.status === 201) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        e.preventDefault();
        return false;
      }
      else if (res.data.status === 204) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านใช้สิทธวันนี้ครบแล้ว',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        e.preventDefault();
        return false;
      }
      else if (res.data.status === 202) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านมีการใช้โปรโมชั่นค้างอยู่ โปรดยกเลิกโปรโมชั่นเก่าก่อน',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        e.preventDefault();
        return false;
      }

    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });
  }

  onChangexgiftlink = event => {
    ////console.log(event.target.value);
    if (event.target !== undefined) {

      this.setState({ truegiftlink: event.target.value });
    }
  };


  async recheccredit(evt) {

    evt.currentTarget.className += " fa-spin";
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    //this.rechecklogin(Username, token);
    this.setState({
      userid: decoded.message.playerid,
      username: decoded.message.playerid,
      firstname: decoded.message.firstname,
      lastname: decoded.message.lastname,
      loginstate: true,
    });
    try {
      // console.log(2);
      await instance.post("/api/v1/checkpoint", {
        userid: Username,
        System: Systems,
        Function: 'recheccredit-App.js'
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        if (res.data.status === 200) {
          localStorage.setItem('auth_token', res.data.token)
          this.setState({
            accnumber: res.data.message.accnumber,
            userid: res.data.message.member_id,
            username: res.data.message.playerid,
            fullname: res.data.message.firstname,
            point: res.data.message.Point,
            balance: res.data.databalance,
            loginstate: true,
          });
          var i, tablinks;
          tablinks = document.getElementsByClassName("btn btn-link -reload-btn p-0");
          for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" fa-spin", "");
          }
        }
        else if (res.data.status === 401) {
          //   console.log(res.data);
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }

      }).catch(async (err) => {
        // console.log(err);
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }
      });
    }
    catch (ex) {
      ////////console.log(ex);
    }
  }

  handleClickdeposit = async (e, price) => {
    this.setState({
      depositamount: price

    });
  }

  onChangexdep = event => {
    ////console.log(event.target.value);
    if (event.target !== undefined) {

      this.setState({ depositamount: event.target.value });
    }
  };

  checkpromotions = async (e, num) => {
    e.preventDefault();
  }

  confirmdepositweb = async (e, num) => {
    window.location.href = num;
  }

  confirmdeposit = async (e, num) => {
    // console.log(num)
    // e.preventDefault();
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    if (token != null) {
      await instance.post("/api/v1/gettrue", {
        userid: Username,
        System: Systems,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        if (res.data.status === 200) {

          this.setState({
            trueaccount: res.data.message.tbltruewallet[0],
          });

        }
      }).catch(async (err) => {
      });

      await instance.post("/api/v1/getredeempromotion", {
        userid: Username,
        System: Systems,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        console.log(799, res);
        if (res.data.status === 200) {
          this.setState({
            proderedeem: res.data.message.tblpromotionredeems,
          });


        }
        else {
          this.setState({
            proderedeem: null,
          });
        }
      }).catch(async (err) => {
      });

      await instance.post("/api/v1/getqrcode", {
        username: Username,
        System: Systems,
        amount: this.state.depositamount
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        //////console.log(res.data.picture)
        this.setState({
          mainacc: res.data.mainacc,
          qrcode: res.data.picture
        });
      });
    }
  }

  redeemrank = async (e, index) => {
    e.preventDefault();
    //console.log(index);

    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      Swal.fire({
        icon: 'warning',
        title: 'คุณต้องการเคลม Rank หรือ ไม่?',
        showCancelButton: true,
        confirmButtonText: 'Save',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          await instance.post("/api/v1/userredeemrank", {
            User_id: Username,
            rankno: index.no,
            System: Systems,
            amount: index.claimamount
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(async (res) => {
            if (res.data.status === 200) {
              const datas = res.data.message;
              this.calllisthistory(Username);
              this.checklogin();
              Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
            }
            else {
              Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
            }
          });

        }
      })
    }

  }

  resetpassword = async (e, type) => {
    e.preventDefault();
    const { phoneNumber, fname, password, oldpassword, passwordcf, checkboxacc, accnumbertxt } = this.state;
    console.log(type, password, oldpassword, passwordcf);
    var token = localStorage.getItem('auth_token');
    if (password === passwordcf) {
      var Username = null;
      var decoded = null;
      if (type === 1 && token != null) {
        decoded = jwt_decode(token);
        Username = decoded.message.playerid;
        instance.post("/api/v1/resetpassword", {
          username: Username,
          oldpass: oldpassword,
          System: Systems,
          newpass: password,
          type: type
        }, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
          if (res.data.status === 200) {
            await Swal.fire({
              title: 'Success!',
              text: 'เปลี่ยนพาสเวิดเรียบร้อย',
              icon: 'success',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 201) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใส่รหัสเก่าผิดพลาด',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 203) {
            await Swal.fire({
              title: 'error!',
              text: 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 202) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใช้พาสเวิดเดิม',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
        });
      }
      else if (type === 2) {
        instance.post("/api/v1/resetpassword", {
          username: phoneNumber,
          oldpass: oldpassword,
          newpass: password,
          System: Systems,
          type: type
        }).then(async (res) => {
          if (res.data.status === 200) {
            await Swal.fire({
              title: 'success!',
              text: 'เปลี่ยนพาสเวิดเรียบร้อย',
              icon: 'success',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 201) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใส่รหัสเก่าผิดพลาด',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 203) {
            await Swal.fire({
              title: 'error!',
              text: 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 202) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใช้พาสเวิดเดิม',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
        });
      }
      else if (type === 3) {
        instance.post("/api/v1/resetpassword", {
          username: phoneNumber,
          oldpass: oldpassword,
          newpass: password,
          System: Systems,
          type: type
        }).then(async (res) => {
          if (res.data.status === 200) {
            await Swal.fire({
              title: 'success!',
              text: 'เปลี่ยนพาสเวิดเรียบร้อย',
              icon: 'success',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 201) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใส่รหัสเก่าผิดพลาด',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 203) {
            await Swal.fire({
              title: 'error!',
              text: 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 202) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใช้พาสเวิดเดิม',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
        });
      }
    }
    else {
      await Swal.fire({
        title: 'error!',
        text: 'คุณใส่พาสเวิดไม่ตรงกัน',
        icon: 'error',
        confirmButtonText: 'ตกลง'
      })
    }
  }

  closemodal = async (e) => {

    console.log(1159, this.state.checkboxcookie);
    if (this.state.checkboxcookie) {
      var date = new Date()
      var checked = this.state.checkboxcookie
      var object = { date, checked }
      console.log(object);
      localStorage.setItem('cookie', JSON.stringify(object))
    }
  }

  nextstep = async (e, num) => {
    e.preventDefault();
    const { stateregis, phoneNumber, checkboxacc, fname, otpphone, accnumbertxt } = this.state


    if (num === 1) {
      console.log(num);
      const check = await this.forgetotpandregister(8);
      console.log(1, phoneNumber.length, check.checked);
      if (phoneNumber.length === 10 && check.checked) {
        this.setState({
          stateregis: 2
        });
      }
      else {
        if (phoneNumber.length !== 10) {
          this.setState({
            stateopterror: "คุณใส่เบอร์ผิดพลาด"
          });
        }
        else if (!check.checked && check.status === 201) {
          this.setState({
            stateopterror: "เบอร์นี้มีคนใช้แล้ว"
          });
        }
        else {
          this.setState({
            stateopterror: "คุณทำรายการถี่เกินไป โปรดรอซักครู่"
          });
        }

        document.getElementById("phoneNumber").style = "border-color: #ef4444;";
        document.getElementById("checkotpforgetphonenormal").style.display = "block";
      }
    }
    else if (num === 2) {

      if (checkboxacc !== 0 && fname !== "" && accnumbertxt !== "") {
        this.setState({
          stateregis: 3
        });
      }
      else {
        await Swal.fire({
          title: 'Warning!',
          text: 'กรุณาเลือกธนาคาร หรือใส่ข้อมูลให้ครบถ้วน !!',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        //////console.log(2, stateregis, checkboxacc);
      }
    }
    else if (num === 3) {
      //////console.log(1, stateregis, num);
      this.setState({
        stateregis: 4
      });
    }
    else if (num === 5) {
      var check = await this.forgetotpandregister(7);
      if (otpphone !== "" && check.checked) {
        this.setState({
          stateregis: 2
        });
      }
      else if (otpphone === "" || otpphone.length < 4) {
        this.setState({
          stateopterror: "คุณใส่ตัวเลขไม่ครบ"
        });
        document.getElementById("otpphone").style = "border-color: #ef4444;";
        document.getElementById("checkotp").style.display = "block";
      }

      else {
        this.setState({
          stateopterror: "รหัสผิดพลาด"
        });
        document.getElementById("otpphone").style = "border-color: #ef4444;";
        document.getElementById("checkotp").style.display = "block";
      }



    }
    else if (num === 11) {
      //////console.log(1, stateregis, num);
      this.setState({
        stateregis: 1
      });
    }
    else if (num === 22) {
      //////console.log(1, stateregis, num);
      this.setState({
        stateregis: 2
      });
    }
  }

  forgetotpandregister = async (type) => {
    const { phoneNumber, otpphoneforget, otpphone } = this.state;
    var recheck = false;
    if (type === 4) {
      await instance.post("/api/v1/resetpassword", {
        username: phoneNumber,
        System: Systems,
        type: type
      }).then(async (res) => {
        recheck = res.data.message;
      });
    }
    else if (type === 5) {
      await instance.post("/api/v1/resetpassword", {
        username: phoneNumber,
        pin: otpphoneforget,
        System: Systems,
        type: type
      }).then(async (res) => {
        recheck = res.data.message;

      });
    }
    else if (type === 6) {
      await instance.post("/api/v1/resetpassword", {
        username: phoneNumber,
        System: Systems,
        type: type
      }).then(async (res) => {
        recheck = res.data.message;
      });
    }
    else if (type === 7) {
      await instance.post("/api/v1/resetpassword", {
        username: phoneNumber,
        pin: otpphone,
        System: Systems,
        type: type
      }).then(async (res) => {
        recheck = res.data.message;

      });
    }
    else if (type === 8) {
      await instance.post("/api/v1/resetpassword", {
        username: phoneNumber,
        pin: otpphone,
        System: Systems,
        type: type
      }).then(async (res) => {
        recheck = res.data.message;

      });
    }
    return recheck
  }

  test = async () => {
    await Swal.fire({
      title: 'Warning!',
      text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
      icon: 'warning',
      confirmButtonText: 'ตกลง'
    })
  }


  nextstepforget = async (e, num) => {
    e.preventDefault();
    console.log(num);
    const { phoneNumber, otpphoneforget } = this.state
    if (num === 1) {
      const check = await this.forgetotpandregister(4);
      if (phoneNumber.length === 10 && check.checked) {
        this.setState({
          stateforget: 2
        });
      }
      else {
        if (phoneNumber.length !== 10) {
          this.setState({
            stateopterror: "คุณใส่เบอร์ผิดพลาด"
          });
        }
        else if (!check.checked && check.status === 201) {
          this.setState({
            stateopterror: "ไม่มีเบอร์นี้ในระบบ"
          });
        }
        else {
          this.setState({
            stateopterror: "คุณทำรายการถี่เกินไป โปรดรอซักครู่"
          });
        }
        document.getElementById("phoneNumberforget").style = "border-color: #ef4444;";
        document.getElementById("checkotpforgetphone").style.display = "block";
      }
    }
    else if (num === 2) {
      const check = await this.forgetotpandregister(5)
      console.log(check)
      if (otpphoneforget !== "" && check.checked) {
        this.setState({
          stateforget: 3
        });
      }
      else if (otpphoneforget === "" || otpphoneforget.length < 4) {
        this.setState({
          stateopterror: "คุณใส่ตัวเลขไม่ครบ"
        });
        document.getElementById("otpphoneforget").style = "border-color: #ef4444;";
        document.getElementById("checkotpforget").style.display = "block";
      }
      else {
        this.setState({
          stateopterror: "รหัสผิดพลาด"
        });
        document.getElementById("otpphoneforget").style = "border-color: #ef4444;";
        document.getElementById("checkotpforget").style.display = "block";
      }

    }
    else if (num === 11) {
      this.setState({
        stateforget: 1
      });
    }
  }

  submitwithdraw = async (e) => {
    //////console.log(this.state.withdrawamount, this.state.balance);
    if (parseFloat(this.state.withdrawamount) <= parseFloat(this.state.balance)) {
      if (parseFloat(this.state.withdrawamount) >= this.state.limitwithdraw.minw) {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
          var decoded = jwt_decode(token);
          var Username = decoded.message.playerid;
          await instance.post("/api/v1/userwithdraw", {
            User_id: Username,
            System: Systems,
            amount: this.state.withdrawamount
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(async (res) => {
            if (res.data.status === 200) {
              await Swal.fire({
                title: 'Success!',
                text: 'รายการถอนเรียบร้อย',
                icon: 'Success',
                confirmButtonText: 'ตกลง'
              })
              window.location.reload(false);
            }
            else if (res.data.status === 203) {
              await Swal.fire({
                title: 'Error!',
                text: 'ท่านติด Turnover อีก ' + res.data.amountturnover + " บาท",
                icon: 'error',
                confirmButtonText: 'ตกลง'
              })
            }
          });
        }
        else {
          await Swal.fire({
            title: 'error!',
            text: 'รายการถอนมีปัญหา โปรดติดต่อพนักงาน',
            icon: 'error',
            confirmButtonText: 'ตกลง'
          })
        }
      }
      else {
        await Swal.fire({
          title: 'Warning!',
          text: 'ขั้นต่ำในการถอน ' + this.state.limitwithdraw.minw + ' บาท',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
      }
    }
    else {
      await Swal.fire({
        title: 'Warning!',
        text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      })
    }

  }

  onChangex = event => {
    // console.log("event", event.target.name);


    if (event.target !== undefined) {
      let nam;
      let val;
      if (event.target.name === "otpphone") {
        document.getElementById("otpphone").style = "";
        document.getElementById("checkotp").style.display = "none";
      }
      else if (event.target.name === "phoneNumber") {
        // document.getElementById("phoneNumberforget").style = "";
        // document.getElementById("checkotpforgetphone").style.display = "none";
      }
      else if (event.target.name === "otpphoneforget") {
        document.getElementById("otpphoneforget").style = "";
        document.getElementById("checkotpforget").style.display = "none";
      }
      else if (event.target.name === "otpphoneforget") {
        document.getElementById("phoneNumber").style = "";
        document.getElementById("checkotpforgetphonenormal").style.display = "none";
      }

      if (event.target.name === undefined) {
        nam = event.name;
        val = event.value;
      } else {
        nam = event.target.name;
        val = event.target.value;
      }
      this.setState({ [nam]: val });
    }
  };


  openchangepassword = (e) => {
    e.preventDefault();
    const { changepassword } = this.state
    ////////console.log(changepassword);
    if (changepassword)
      this.setState({
        changepassword: false
      });
    else
      this.setState({
        changepassword: true
      });

  }

  cancelwithdraw = async (e, index) => {
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      Swal.fire({
        icon: 'warning',
        title: 'คุณต้องการยกเลิกการถอนเงินใช่ หรือ ไม่?',
        showCancelButton: true,
        confirmButtonText: 'Save',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          await instance.post("/api/v1/userwithdrawcancel", {
            User_id: Username,
            tid: index.tid,
            System: Systems,
            amount: index.amount
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(async (res) => {
            if (res.data.status === 200) {
              const datas = res.data.message;
              this.calllisthistory(Username);
              Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
            }
            else {
              Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
            }
          });

        }
      })
    }

  }

  cancelredeempromotion = async (e, index) => {
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    console.log(1462222, index[0]);
    if (token != null) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      Swal.fire({
        icon: 'warning',
        title: 'คุณต้องการยกเลิกการใช้โปรใช่ หรือ ไม่?',
        showCancelButton: true,
        confirmButtonText: 'Save',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          await instance.post("/api/v1/usercancelpromotion", {
            User_id: Username,
            no: index[0].redeemno,
            System: Systems,
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(async (res) => {
            if (res.data.status === 200) {
              this.confirmdeposit(null, 0);
              Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
            }
            else {
              Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
            }
          });

        }
      })
    }

  }


  async calllisthistory(userid) {
    await instance.post("/api/v1/userwithdrawlist", {
      userid: userid,
      Cat_ID: 0,
      System: Systems,
      page: 1,
      type: 1
    }).then((res) => {
      if (res.data.status === 200) {
        const datas = res.data.message;
        //  //console.log(datas.withdrawlist);
        this.setState({
          historylist: datas.withdrawlist
        });

      }
      else {
        // this.CheckThisGoalError();
      }
    });

  }

  async saveclick(linkid) {
    await instance.post("/api/v1/saveclick", {
      linkid: linkid,
      System: Systems,
    }).then((res) => {
      var date = new Date()
      this.setState({
        userrecomend: linkid,
        link: linkid
      });
      var username = linkid
      var object = { date, username }
      localStorage.setItem('register', JSON.stringify(object))
    });

  }

  async saveclickaff(linkid) {
    await instance.post("/api/v1/saveclickaff", {
      linkid: linkid,
      System: Systems,
    }).then((res) => {
      if (res.data.status == 200) {
        var date = new Date()
        this.setState({
          userrecomend: res.data.username,
          link: linkid
        });
        var username = res.data.username
        var object = { date, username }
        localStorage.setItem('aff', JSON.stringify(object))
      }
    }).catch(async (err) => {
    });

  }

  componentDidMount = async () => {
    const endpoint = "https://betner.co:9000";
    const socket = io.connect(endpoint);
    var roomid = Systems;
    var obj = { roomid }
    var json = JSON.stringify(obj);
    socket.on('connect', function (data) {
      var roomid = "pgexlne"
      var obj = { roomid }
      var json = JSON.stringify(obj);
      socket.emit('new', json);
    });

    // const space = window.location.host
    const search = window.location.href;
    var test = search.split("login=")[1]
    if (test != undefined) {
      localStorage.setItem('auth_token', test.split("&")[0]);
      window.location.replace('/');
    }

    var token = localStorage.getItem('auth_token');
    if (token != null) {
      try {
        var decoded = jwt_decode(token);
        await this.checklogin();
      }
      catch (ex) {
      //  window.location.replace('https://trixie168.com/');
        // window.location.replace('http://localhost:3000/');
      }
    }
    else{
     // window.location.replace('https://trixie168.com/');
    }
    try {
      await instance.post("/api/v1/mainsetting", {
        System: Systems,
      }).then((res) => {
        if (res.data.status === 200) {
          const datas = res.data.message;
          var min = res.data.tblcatagoryx[0].limitdeposit;
          var max = res.data.tblcatagoryx[0].maxdeposit;
          var minw = res.data.tblcatagoryx[0].minwithdraw;
          var maxw = res.data.tblcatagoryx[0].maxwithdraw;
          var maxwithdrawstatus = res.data.tblcatagoryx[0].withdrawmax
          var limit = { min, max }
          var limitwithdraw = { minw, maxw }
          this.setState({
            maxwithdrawstatus: maxwithdrawstatus,
            linelink: datas[1].value,
            webnamex: datas[7].value,
            System: res.data.tblcatagoryx[0].System,
            cashoutSet: res.data.tblcashoutsetting[0],
            inviteSet: res.data.tblinvitedsetting[0],
            datas: datas,
            limit: limit,
            limitwithdraw: limitwithdraw,
          });

          this.confirmdeposit(null, 0);
          this.getcasinolist();
          this.getslotlist();
          this.getsportlist();
          this.getsfishinglist();
          this.promotionlist();
        }
        else {
          // this.CheckThisGoalError();
        }
      });
    }
    catch (ex) {
      console.log(ex);
    }



  }
  async recheckbox() {
    var checked = false;
    if (this.state.checkboxcookie) checked = false;
    else checked = true;
    this.setState({
      checkboxcookie: checked,
    });

  }
  onChange = async event => {

    if (event.target !== undefined) {
      let nam;
      let val;
      if (event.target.name === undefined) {
        nam = event.name;
        val = event.value;
      } else {
        nam = event.target.name;
        val = event.target.value;
      }
      if (nam === "withdrawamount") {
        if (this.state.maxwithdrawstatus === 1) {
          this.setState({ [nam]: this.state.balance });
          await Swal.fire({
            title: 'Warning!',
            text: 'บังคับถอนยอดทั้งหมด',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
        }
        else if (Number(val) <= Number(this.state.balance)) {
          this.setState({ [nam]: val });
        }
        else {
          await Swal.fire({
            title: 'Warning!',
            text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
        }
      } else {
        this.setState({ [nam]: val });
      }

    }
  };

  async checkuseradvice() {
    instance.post("/api/v1/checkuseradvice", {
      User_id: this.state.username,
      page: 1,
      System: Systems,
    }).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.message.useradvicelist);
        this.setState({
          useradvice: res.data.message.useradvicelist
        });
      }
      else {
        // console.log(res.data);
        // this.CheckThisGoalError();
      }
    });
  }


  async checkcashoutlist() {
    await instance.post("/api/v1/checkcashoutlist", {
      User_id: this.state.username,
      page: 1,
      System: Systems,
    }).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.message.cashoutlist);
        this.setState({
          cashoutlist: res.data.message.cashoutlist
        });
      }
      else {
        // console.log(res.data);
        // this.CheckThisGoalError();
      }
    });
  }






  checklogin = async () => {

    var token = localStorage.getItem('auth_token');
    //console.log(token);
    if (token != null) {
      var cookie = localStorage.getItem('cookie');
      if (cookie != 'null' && cookie != null) {
        var persons = JSON.parse(cookie);
        if (persons.checked != null) {
          this.setState({
            checkboxcookie: persons.checked,
          });
        }
      }

      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      await this.rechecklogin(Username, token);
    }
    else {
      this.setState({
        loginstate: false,
      });
    }
  }
  async modalerror(object) {
    await Swal.fire({
      title: object.title,
      text: object.text,
      icon: object.title,
      confirmButtonText: 'ตกลง'
    })
  }
  validatergisterForm = async (e) => {
    e.preventDefault();
    const { phoneNumber, fname, password, passwordcf, checkboxacc, accnumbertxt, link } = this.state;
    if (password.length < 6) {
      var title = 'Error!'
      var text = 'Password คุณไม่ครบ 6 ตัวโปรดตรวจสอบ'
      var object = { title, text }
      this.modalerror(object);
    }

    else if (phoneNumber === "") {

      title = 'Error!'
      text = 'คุณไม่ทำการใส่ เบอร์โทรศัพท์'
      object = { title, text }
      this.modalerror(object);

    }
    else if (accnumbertxt === "") {
      title = 'Error!'
      text = 'คุณไม่ทำการใส่เลขบัญชีธนาคาร'
      object = { title, text }
      this.modalerror(object);

    }
    else if (fname === "") {

      title = 'Error!'
      text = 'คุณไม่ทำการใส่ ชื่อ-นามสกุล'
      object = { title, text }
      this.modalerror(object);

    }
    else if (checkboxacc === 0) {
      title = 'Error!'
      text = 'คุณยังไม่ได้เลือกธนาคาร'
      object = { title, text }
      this.modalerror(object);
    }
    else if (password !== passwordcf) {
      title = 'Error!'
      text = 'คุณไส่พาสเวิดไม่ตรงกัน'
      object = { title, text }
      this.modalerror(object);
    }

    else {
      instance.post("/api/v1/register", {
        User_id: phoneNumber,
        name: fname,
        password: password,
        accbank: checkboxacc,
        accno: accnumbertxt,
        System: Systems,
        link: link,
        Useradvise: this.state.userrecomend
      })
        .then(async (res) => {
          if (res.data.status === 200) {

            await Swal.fire({
              title: 'Success!',
              text: 'ทำการสมัครเรียบร้อย',
              icon: 'success',
              confirmButtonText: 'ตกลง'
            })

            await instance.post("/api/v1/userlogin", {
              userid: phoneNumber,
              password: password,
              System: Systems,
              Function: 'validateLoginForm-navbar.js'
            }).then((res) => {
              if (res.status === 200) {
                if (res.data.status === 200) {
                  localStorage.setItem('auth_token', res.data.token);
                  window.location.href = '/'
                }
              }
            });

          }
          else if (res.data.status === 202) {
            this.setState({
              errorstatus: 1,
              errortext: 'Username นี้ทำการสมัครไปแล้ว'
            });
          }
          else {
            this.setState({
              errorstatus: 1,
              errortext: 'Username หรือ ชื่อในการสมัครไม่ถูกต้อง'
            });
          }
        });
    }


  }
  rechecklogin = async (Username, token) => {
    try {

      //const { webnamex } = this.state
      // console.log(1406, Username);
      //console.log(3);
      const search = window.location.href;
      const paramx = new URLSearchParams(search);
      // const space = search.split("/")
      const space = window.location.host
      var userhash = md5(Username);
      //  const linkaff = "https://Trixie168BET.com/?register=" + userhash;
      const linkaff = "https://" + space + "/register?reg=" + userhash;
      await instance.post("/api/v1/checkpoint", {
        userid: Username,
        System: Systems,
        Update: userhash,
        Function: 'rechecklogin-App.js'
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        // //console.log(res.data);
        if (res.data.status === 200) {
          this.calllisthistory(Username);
          console.log(443, res.data);
          localStorage.setItem('auth_token', res.data.token)
          var rankall = res.data.tblrankall;
          const found = rankall.find(obj => {
            return obj.checked === 1;
          });

          this.setState({
            tblrankall: res.data.tblrankall,
            linkaff: linkaff,
            buttoncliam: (found === undefined) ? true : false,
            tblnextrank: res.data.tblnextrank,
            rankpic: (res.data.tblrank.image == null || res.data.tblrank.image == "") ? "https://betner.co/build/rank/1.jpeg" : (res.data.tblrank.picrack == null || res.data.tblrank.picrack == "") ? "https://betner.co/build/rank/" + res.data.tblrank.image : res.data.tblrank.picrack,
            rankname: res.data.tblrank.name,
            rankob: res.data.tblrank,
            tblprofile: res.data.message,
            rankpoint: res.data.rankpoint,
            accnumber: res.data.message.accnumber,
            userid: res.data.message.member_id,
            username: res.data.message.playerid,
            fullname: res.data.message.firstname,
            point: res.data.message.Point,
            balance: res.data.databalance,
            loginstate: true,
          });
        }
        else if (res.data.status === 401) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = 'https://trixie168.com/';
        }

      }).catch(async (err) => {
        // console.log(err);
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = 'https://trixie168.com/';
        }
      });
    }
    catch (ex) {
      ////////console.log(ex);
    }

  }

  render() {
    //////////console.log(this.state)this.state.userrecomend
    const { cashoutSet, prolistx, loginstate, trueaccount, webnamex, stateopterror, changepassword, username, balance, accnumber, fullname, mainacc, limit, rankob, rankpoint, tblnextrank, userrecomend, linkaff, tblprofile, proderedeem, proderedeemlist, prodetail } = this.state


    console.log(1938, proderedeem)
    var accnumberx;
    var limits = limit
    if (accnumber != null) {
      // ////////console.log(accnumber[0].accno);
      accnumberx = accnumber[0];
    }

    return (
      <>
        <div className="hitopjikko">
          <img className="bottomwhite" src="/build/web/igame-index-lobby-wm/img/ez-slot-cover-foreground.png" />
          <img
            src="/build/web/igame-index-lobby-wm/img//ez-slot-ic-cover-gift.png"
            className="giftanimate"
            alt="Jikko888 รูปของขวัญกล่องใหญ่"
          />
        </div>
        <div className="containanimation">
          <img src="/build/web/igame-index-lobby-wm/img//bg-allcasino-2.png" className="-bg-2" />
          <img src="/build/web/igame-index-lobby-wm/img/world4.png?v=1" className="wordanimation" />
        </div>
        <div className="containall">
          <div
            data-aos="fade-right"
            className="contentmain boxcolor aos-init aos-animate"
          >
            {/* ส่วนหัว /ข้อมูลส่วนตัว----------------------------------------------- */}
            <div className="headmain">
              <table width="100%">
                <tbody>
                  <tr>
                    <td style={{ width: "15%", textAlign: "center", paddingLeft: 15 }}>
                      <img src="/build/web/ez-bet/img/logo-black.png?v=1" width="50px" />
                    </td>
                    <td style={{ width: "50%", textAlign: "left", fontSize: 18 }}>
                      <i
                        className="fas fa-user"
                        style={{ fontSize: 16, color: "#fff" }}
                      />{" "}
                      {fullname}
                      <br />
                      <img src={accnumber !== null ? accnumberx.img_path : ""} alt={fullname} width="17px" />{" "}
                      <span> {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}</span>
                    </td>
                    <td style={{ width: "15%", textAlign: "right" }}>
                      <table align="right">
                        <tbody>
                          <tr>
                            <td className="righttopmain">
                              <span className="bglangbtn">
                                <img
                                  //  onClick="opentab(event, 'language')"
                                  onClick={(e) => this.opentab(e, 'language')}
                                  className="languagebtn"
                                  src="/build/web/igame-index-lobby-wm/img/translate.png"
                                />
                              </span>
                            </td>
                            <td className="paddinglefttop">
                              <button
                                className="logoutbtn"
                                onClick={(e) =>
                                  this.logout(e)
                                }
                              >
                                <i className="fal fa-power-off" />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="containmoney">
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="50%" style={{ paddingLeft: 20 }}>
                      <i
                        // onClick="opentab(event, 'section01')"
                        onClick={(e) => this.opentab(e, 'section01')}
                        id="opensection01"
                        style={{ cursor: "pointer" }}
                        className="fal fa-wallet"
                      />
                    </td>
                    <td width="50%" style={{ textAlign: "right" }}>
                      <span style={{ fontSize: 17 }}>
                        <i className="fad fa-coin" /> ยอดเครดิตของคุณ{" "}
                      </span>
                      <br />
                      <span style={{ color: "#ffe37c", fontSize: 35 }}>
                        {" "}
                        {balance}
                        <span style={{ fontSize: 15 }}> บาท</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr style={{ margin: 5, borderTop: "1px solid #a0c9e4" }} />
              <div style={{ paddingLeft: 20, marginTop: 10, marginBottom: 15 }}>
                <i className="fad fa-gift" style={{ color: "#fad275" }} /> โปรโมชั่น : {" "}
                {proderedeem != null ? <span style={{ color: "#ffb300" }} >   {proderedeem[0].proname}   <a href={() => false} style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.cancelredeempromotion(e, proderedeem)
                  }}
                  className="-link-change-password" ><u style={{ color: "red" }}>ยกเลิก</u></a></span> : "ไม่รับโบนัส"}
              </div>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="50%" style={{ textAlign: "center" }}>
                      <button
                        className="btnfriend mcolor"
                        // onClick="opentab(event, 'friend')"
                        onClick={(e) => this.opentab(e, 'friend')}
                      >
                        <i className="fal fa-users-medical" /> แนะนำเพื่อน
                      </button>
                    </td>
                    <td width="50%" style={{ textAlign: "center" }}>
                      <button
                        className="btncommis bkcolor"
                        // onClick="opentab(event, 'comis')"
                        onClick={(e) => this.opentab(e, 'comis')}
                      >
                        <i className="fal fa-hands-usd" /> คืนยอดเสีย
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* END  ส่วนหัว /ข้อมูลส่วนตัว----------------------------------------------- */}
            {/* Main menu select01 */}
            <i
              // onClick="opentab(event, 'section01')"
              onClick={(e) => this.opentab(e, 'section01')}
              id="defaultOpen"
              hidden
              className="fal fa-plus tablinks active"
            />
            <div
              id="section01"
              className="tabcontent mainsection"
              style={{ display: "block" }}
            >
              <a href='#' onClick={(e) => this.opentab(e, 'playgame')}>
                <div className="headerprocess" >
                  <img src="/build/web/igame-index-lobby-wm/img/playgame.gif" />
                </div>
              </a>
              <div className="menucontain">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="tdgridicon">
                        <span
                          onClick={(e) => this.opentab(e, 'deposit')}
                        // onClick="opentab(event, 'deposit')
                        >
                          <i className="fal fa-plus tablinks" />
                          <br />
                          ฝากเงิน
                        </span>
                      </td>
                      <td className="tdgridicon">
                        <span
                          // onClick="opentab(event, 'withdraw')"
                          onClick={(e) => this.opentab(e, 'withdraw')}
                        >
                          <i className="fal fa-minus tablinks" />
                          <br />
                          ถอนเงิน
                        </span>
                      </td>
                      <td className="tdgridicon">
                        <span
                          onClick={(e) => this.opentab(e, 'promotion')}
                        // onClick="opentab(event, 'promotion')"

                        >
                          <i
                            className="fal fa-gift button--resize tablinks"
                            id=""
                            style={{ fontSize: 25, paddingTop: 13 }}
                          />
                          <br />
                          โปรโมชั่น
                        </span>
                      </td>
                      <td className="tdgridicon">
                        <span
                          onClick={(e) => this.opentab(e, 'event')}
                        // onClick="opentab(event, 'event')"
                        >
                          <i
                            className="fal fa-calendar-star tablinks"
                            style={{ fontSize: 25, paddingTop: 14 }}
                          />
                          <br />
                          กิจกรรม
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="tdgridicon">
                        <span
                          onClick={(e) => this.opentab(e, 'password')}
                        // onClick="opentab(event, 'password')"

                        >
                          <i
                            className="fal fa-lock tablinks"
                            style={{ fontSize: 25, paddingTop: 13 }}
                          />
                          <br />
                          เปลี่ยนรหัส
                        </span>
                      </td>
                      <td className="tdgridicon">
                        <span
                          onClick={(e) => this.opentab(e, 'history')}
                        // onClick="opentab(event, 'history')"
                        >
                          <i
                            className="fal fa-history tablinks"
                            id=""
                            style={{ fontSize: 25, paddingTop: 14 }}
                          />
                          <br />
                          ประวัติ
                        </span>
                      </td>
                      <td className="tdgridicon">
                        <span
                          //  onClick="opentab(event, 'playgame')"
                          onClick={(e) => this.opentab(e, 'playgame')}
                        >
                          <i
                            className="fal fa-gamepad-alt tablinks"
                            id=""
                            style={{ fontSize: 25, paddingTop: 14 }}
                          />
                          <br />
                          เข้าเล่นเกม
                        </span>
                      </td>
                      <td className="tdgridicon">
                        <span
                          // onClick="opentab(event, 'account')"

                          onClick={(e) => this.opentab(e, 'account')}
                        >
                          <i
                            className="fal fa-user-alt tablinks"
                            id=""
                            style={{ fontSize: 25, paddingTop: 14 }}
                          />
                          <br />
                          บัญชี
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="swiper-container-2 swiper-initialized swiper-horizontal swiper-backface-hidden">
                <div
                  className="swiper-wrapper"
                  style={{
                    transitionDuration: "0ms",
                    transitionDelay: "0ms",
                    transform: "translate3d(-609.446px, 0px, 0px)"
                  }}
                  id="swiper-wrapper-3afa638f61f0ee14"
                  aria-live="polite"
                >
                  <div
                    className="swiper-slide swiper-slide-prev"
                    role="group"
                    aria-label="1 / 3"
                    style={{ marginRight: 50 }}
                  >
                    <img src="/build/web/igame-index-lobby-wm/img/6.jpg?v=1" />
                  </div>
                  <div
                    className="swiper-slide swiper-slide-active"
                    role="group"
                    aria-label="2 / 3"
                    style={{ marginRight: 50 }}
                  >
                    <img src="/build/web/igame-index-lobby-wm/img/5.jpg?v=1" />
                  </div>
                  <div
                    className="swiper-slide swiper-slide-next"
                    role="group"
                    aria-label="3 / 3"
                    style={{ marginRight: 50 }}
                  >
                    <img src="/build/web/igame-index-lobby-wm/img/6.jpg?v=1" />
                  </div>
                </div>
                {/* Add Pagination */}
                <div
                  className="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-bullets-dynamic"
                  style={{ width: 90 }}
                >
                  <span
                    className="swiper-pagination-bullet swiper-pagination-bullet-active-prev"
                    style={{ left: 0 }}
                  />
                  <span
                    className="swiper-pagination-bullet swiper-pagination-bullet-active swiper-pagination-bullet-active-main"
                    aria-current="true"
                    style={{ left: 0 }}
                  />
                  <span
                    className="swiper-pagination-bullet swiper-pagination-bullet-active-next"
                    style={{ left: 0 }}
                  />
                </div>
                <div
                  className="swiper-button-prev swiper-button-white"
                  tabIndex={0}
                  role="button"
                  aria-label="Previous slide"
                  aria-controls="swiper-wrapper-3afa638f61f0ee14"
                  aria-disabled="false"
                />
                <div
                  className="swiper-button-next swiper-button-white"
                  tabIndex={0}
                  role="button"
                  aria-label="Next slide"
                  aria-controls="swiper-wrapper-3afa638f61f0ee14"
                  aria-disabled="false"
                />
                <span
                  className="swiper-notification"
                  aria-live="assertive"
                  aria-atomic="true"
                />
              </div>
            </div>
            {/* end Section 1 main */}
            {/* ถ้าเรียกใช้แท็บ >> กลับหน้าหลัก */}
            <div
              className="backtohome button-resize-1"
              id="containbacktohome"
              onClick={(e) => this.opentab(e, 'section01')}
              style={{ display: "none" }}
            >
              <span id="backtohome" style={{ cursor: "pointer" }}>
                <i className="fas fa-home-lg-alt homebtn" />{" "}
                <i
                  className="fal fa-long-arrow-left"
                  style={{ fontWeight: "bold", color: "#c5c5c5" }}
                ></i>{" "}
                กลับหน้าหลัก
              </span>
              &nbsp;&nbsp;&nbsp;
              <span
                id="backtohistory"
                // onClick="opentab(event, 'history')"
                onClick={(e) => this.opentab(e, 'history')}
                style={{ cursor: "pointer", display: "none" }}
              >
                <i className="fas fa-history homebtn" />{" "}
                <i
                  className="fal fa-long-arrow-left"
                  style={{ fontWeight: "bold", color: "#c5c5c5" }}
                ></i>{" "}
                กลับหน้าประวัติ
              </span>
            </div>
            {/* ------------------------ */}
            {/* Deposit section */}
            <div id="deposit" className="tabcontent" style={{ display: "none" }}>
              <div className="headerprocess">
                <i className="fal fa-plus-circle" /> ฝากเงิน
              </div>
              <div className="accordion-div">
                <div className="pdingaccord">
                  <button className="accordion">
                    <img src="/build/web/igame-index-lobby-wm/img//bank.svg" height="50px" /> &nbsp; ธนาคาร
                  </button>
                  <div className="panel" style={{maxHeight:"214px"}}>
                    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                      <div align="center" className="tabletruewallet">
                        {mainacc !== null ? <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                            fontSize: 13,
                            padding: 5
                          }}
                        >
                          <img
                            src={mainacc.img_path}
                            width="70px"
                            style={{ marginBottom: 5 }}
                          />
                          <br />
                          {mainacc.fullname_th}
                          <br />
                          {
                            mainacc.accnumber.substring(0, 3) + "-" + mainacc.accnumber.substring(3, 9) + "-" + mainacc.accnumber.substring(9, 12)
                          }
                          <br />{mainacc.first_name_en + " " + mainacc.last_name_en} <br />
                          <button onClick={() => navigator.clipboard.writeText(mainacc.accnumber)} data-message="คัดลอกแล้ว" className="copybtn mcolor" data-copy-me={mainacc.accnumber}>
                            คัดลอก<span hidden>{mainacc.accnumber}</span>
                          </button>
                        </div> : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pdingaccord">
                  <button className="accordion" style={{ fontSize: 20 }}>
                    <img src="/build/web/igame-index-lobby-wm/img//truewallet.png" height="30px" /> &nbsp;
                    <span style={{ color: "#ed1c24" }}>true</span>
                    <span style={{ color: "#f38020" }}>money</span>
                    <span style={{ color: "#fff" }}> wallet</span>
                  </button>
                  <div className="panel">
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <div align="center" className="tabletruewallet">
                        {trueaccount !== null ? <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                            fontSize: 13,
                            padding: 5
                          }}
                        >
                          <img
                            src="/build/web/igame-index-lobby-wm/img//truewallet.svg"
                            width="70px"
                            style={{ marginBottom: 5 }}
                          />
                          <br />
                          ทรูมันนี่วอลเล็ท
                          <br />
                          {trueaccount.telnumber}
                          <br />
                          {trueaccount.name}
                          <br />
                          <button onClick={() => navigator.clipboard.writeText(trueaccount.telnumber)} data-message="คัดลอกแล้ว" className="copybtn mcolor" data-copy-me={trueaccount.telnumber}>
                            คัดลอก<span hidden>{trueaccount.telnumber}</span>
                          </button>
                        </div> : ""}
                        {/* <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                            fontSize: 13,
                            padding: 5
                          }}
                        >
                          <img
                            src="/build/web/igame-index-lobby-wm/img//truewallet.svg"
                            width="70px"
                            style={{ marginBottom: 5 }}
                          />
                          <br />
                          ทรูมันนี่วอลเล็ท
                          <br />
                          062-539-8783
                          <br />
                          ทดสอบ ทดสอบ
                          <br />
                          <button onClick="myAlertTop()" className="copybtn mcolor">
                            คัดลอก<span hidden="">062-539-8783</span>
                          </button>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                            fontSize: 13,
                            padding: 5
                          }}
                        >
                          <img
                            src="/build/web/igame-index-lobby-wm/img//truewallet.svg"
                            width="70px"
                            style={{ marginBottom: 5 }}
                          />
                          <br />
                          ทรูมันนี่วอลเล็ท
                          <br />
                          062-539-8783
                          <br />
                          ทดสอบ ทดสอบ
                          <br />
                          <button onClick="myAlertTop()" className="copybtn mcolor">
                            คัดลอก<span hidden="">062-539-8783</span>
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Deposit section */}
            {/* Withdraw section */}
            <div id="withdraw" className="tabcontent" style={{ display: "none" }}>
              <div className="headerprocess">
                <i className="fal fa-minus-circle" /> ถอนเงิน
              </div>
              <div className="bgback" style={{ padding: 20 }}>
                <table className="tablewd01 " align="center">
                  <tbody>
                    {accnumber !== null ? <tr>
                      <td style={{ textAlign: "left", color: "black" }}>
                        <span style={{ fontSize: 20, fontWeight: "bold" }}>
                          ถอนเงินเข้าบัญชีธนาคาร
                        </span>{" "}
                        <br />
                        ชื่อ :   {fullname} <br />
                        เลขที่บัญชี :  {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""} <br />
                        {accnumber !== null ? accnumberx.fullname_th : ""}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <img width={50} src={accnumberx.img_path} />
                      </td>
                    </tr> : ""}
                  </tbody>
                </table>
              </div>
              <div style={{ padding: "0px 25px" }}>
                <div className="wdsec02">
                  <table className="tablewd01" align="center">
                    <tbody>
                      <tr>
                        <td> จำนวนเงินที่ถอนได้</td>
                        <td style={{ textAlign: "right" }}> ยอดถอนขั้นต่ำ</td>
                      </tr>
                      <tr>
                        <td>฿ {balance}</td>
                        <td style={{ textAlign: "right" }}> ฿{this.state.limitwithdraw.minw}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tablewd01" style={{ margin: "10px auto" }}>
                <div style={{ padding: 20 }}>
                  <div className="form-group">
                    <span style={{ fontSize: 15, color: "black" }}>
                      จำนวนเงินที่ต้องการถอน
                    </span>
                    <input
                      type="text"
                      id="withdraw_amount"
                      onChange={this.onChange}
                      value={this.state.withdrawamount}
                      name="withdrawamount"
                      required="required"
                      pattern="[0-9]*"
                      inputMode="text"
                      className=" loginform01 number"
                      placeholder="฿ 0.00"
                      style={{ padding: 10, marginTop: 10 }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{ textAlign: "center", marginTop: "-10px", padding: "0px 30px" }}
              >
                <button className="mcolor colorbtn01"
                  onClick={(e) => this.submitwithdraw(e)}
                >
                  <i className="fal fa-hand-holding-usd" /> ยืนยันการถอน
                </button>
              </div>
            </div>
            {/* End Withdraw section */}
            {/* password section */}
            <div id="password" className="tabcontent" style={{ display: "none" }}>
              <div className="headerprocess">
                <i className="fal fa-lock" /> เปลี่ยนรหัสผ่าน
              </div>
              <div className="containprocess " style={{ padding: "0 30px" }}>
                <div style={{ marginTop: 10 }}>
                  <label htmlFor="exampleInputEmail1" style={{ backgroundColor: "#f10000" }}>รหัสผ่านเก่า</label>
                  <div className="iconlogin">
                    <i className="fal fa-lock" style={{ fontSize: 20 }} />
                  </div>
                  <input
                    type="password"
                    className=" loginform01"
                    onChange={this.onChangex}
                    id="oldpassword"
                    name="oldpassword"
                    required="required"
                    placeholder="กรอกรหัสผ่านเก่า"
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <label htmlFor="exampleInputEmail1">รหัสผ่านใหม่</label>
                  <div className="iconlogin">
                    <i className="fal fa-lock" style={{ fontSize: 20 }} />
                  </div>
                  <input
                    onChange={this.onChangex}
                    className=" loginform01"
                    type="password"
                    id="password"
                    name="password"
                    required="required"
                    placeholder="กรอกรหัสผ่านใหม่"
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  <label htmlFor="exampleInputEmail1">ยืนยันรหัสผ่านใหม่</label>
                  <div className="iconlogin">
                    <i className="fal fa-lock" style={{ fontSize: 20 }} />
                  </div>
                  <input
                    onChange={this.onChangex}
                    type="password"
                    id="passwordcf"
                    name="passwordcf"
                    required="required"
                    className=" loginform01"
                    placeholder="ยืนยันรหัสผ่านใหม่"
                  />
                </div>
                <div className="text-center mt-4">
                  <button onClick={(e) => {
                    this.resetpassword(e, 1)
                  }} className="mcolor colorbtn01">
                    <i className="fal fa-sign-in" /> เปลี่ยนรหัสผ่าน
                  </button>
                </div>

              </div>
            </div>
            {/* End password section */}
            {/* promotion section */}
            <div id="promotion" className="tabcontent" style={{ display: "none" }}>
              <div className="headerprocess">
                <i className="fal fa-gift" /> โปรโมชั่น
              </div>
              <div className="containprocess containpromotion">
                <div className="swiper-container swiper-coverflow swiper-3d swiper-initialized swiper-horizontal swiper-watch-progress">
                  <div
                    className="swiper-wrapper"
                    id="swiper-wrapper-1e51ec59e83dc19a"
                    aria-live="polite"
                    style={{
                      cursor: "grab",
                      transitionDuration: "0ms",
                      transitionDelay: "0ms"
                    }}
                  >
                    {prolistx.map(prolists => (
                      <div
                        className="swiper-slide"
                        style={{ backgroundImage: 'url(' + prolists.linkpicture + ')' }}
                      >
                        <div className="detailpro">
                          <br />
                          <div style={{ textAlign: "center" }}>
                            <button onClick={(e) => this.redeempro(e, prolists.no)} className="btnbonus mcolor">รับโบนัส</button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Add Pagination */}
                  <span
                    className="swiper-notification"
                    aria-live="assertive"
                    aria-atomic="true"
                  />
                </div>
                <button
                  className="btnnext bkcolor"
                  tabIndex={0}
                  aria-label="Next slide"
                  aria-controls="swiper-wrapper-1e51ec59e83dc19a"
                  aria-disabled="false"
                >
                  <i className="fad fa-chevron-right" />
                </button>
                <button
                  className="btnprev bkcolor"
                  tabIndex={0}
                  aria-label="Previous slide"
                  aria-controls="swiper-wrapper-1e51ec59e83dc19a"
                  aria-disabled="false"
                >
                  <i className="fad fa-chevron-left" />
                </button>
              </div>
            </div>
            {/* End promotion section */}
            {/* history section */}
            <div id="history" className="tabcontent" style={{ display: "none" }}>
              <div className="headerprocess">
                <i className="fal fa-history" /> ประวัติ
              </div>
              <div className="containprocess " style={{ marginTop: 10 }}>
                <table style={{ width: "100%" }}>
                  <thead style={{ textAlign: "center" }}>
                    <tr>
                      <th className="headdeposit active">
                        <button id="btndeposithis">
                          <i className="fal fa-plus-circle" style={{ color: "#fff" }} />{" "}
                          ประวัติการฝาก
                        </button>
                      </th>
                      <th className="headwithdraw">
                        <button id="btnwithdrawwhis">
                          <i
                            className="fal fa-minus-circle"
                            style={{ color: "#fff" }}
                          />{" "}
                          ประวัติการถอน
                        </button>
                      </th>
                    </tr>
                  </thead>
                </table>
                <div id="deposithis">
                  <span style={{ color: "black" }}>
                    {" "}
                    แสดงข้อมูล<span style={{ color: "#9fe7a0" }}>ฝากเงิน</span> 7
                    วันย้อนหลัง
                  </span>
                  <div className="containloophisdps">
                    {/* Loop หน้าฝากเงิน -------------------------------------------------------------- */}
                    {this.state.historylist.map((listitem, i) => (
                      listitem.type == "DE" || listitem.type == "BN" ?
                        <div className="historyofdps" >
                          <table width="100%">
                            <tbody>
                              <tr>
                                <td width="50%" style={{ paddingTop: 7 }}>
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td style={{ paddingRight: 5 }}>
                                          {" "}
                                          <img
                                            className="backlogohis"
                                            src="/build/web/igame-index-lobby-wm/img/scb.svg"
                                          />
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                          <span className="spanofbankhis">
                                            {(listitem.type === "BN") ? "*โบนัสพิเศษ" : (listitem.type === "DE") ? "ฝากเงิน" : "ถอนเงิน"}
                                          </span>
                                          <br />
                                          <span className="spanofbankhis">
                                            <span style={{ display: (listitem.status === "0") ? "" : "none", margin: "5px" }}>
                                              <a href={() => false} style={{ cursor: "pointer" }} onClick={(e) => {
                                                this.cancelwithdraw(e, listitem)
                                              }}
                                                className="-link-change-password" ><u>ยกเลิก</u></a>
                                            </span>
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td
                                  width="50%"
                                  style={{ textAlign: "right" }}
                                >
                                  <div >
                                    {" "}
                                    <span >
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "2") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <i className="fas fa-spinner fa-spin"></i>
                                        <span className="-state-title">กำลังดำเนินการ</span>
                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "0") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <i className="fas fa-spinner fa-spin"></i>
                                        <span className="-state-title">รอดำเนินการ</span>

                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "4") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <img
                                          src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                          className="-ic"
                                          width={15}
                                          alt=""
                                        />
                                        {" "}
                                        <span className="-state-title">ยกเลิก (ยกเลิกโดยผู้เล่น)</span>
                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "5") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <img
                                          src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                          className="-ic"
                                          width={15}
                                          alt=""
                                        />
                                        {" "}
                                        <span className="-state-title">ไม่สำเร็จ (โดยระบบ)</span>
                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "1") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <img
                                          src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                          className="-ic"
                                          width={15}
                                          alt=""
                                        />
                                        {" "}
                                        <span className="-state-title">สำเร็จ</span>
                                      </div>
                                    </span>
                                  </div>
                                  <span className="moneyhisdps">
                                    {" "}
                                    <i className="fal fa-plus-circle plushis" />   {" "}{listitem.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                    บาท
                                  </span>
                                  <br />
                                  <span style={{ fontSize: 11 }}>{moment(listitem.createdate).format('DD/MM/YY') + " - " + moment(listitem.createdate).format('HH:mm') + " น."} </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div> : ""
                    ))}
                    {/* End Loop หน้าฝากเงิน -------------------------------------------------------------- */}
                  </div>
                </div>
                <div id="withdrawwhis" style={{ display: "none" }}>
                  <span style={{ color: "black" }}>
                    {" "}
                    แสดงข้อมูล<span style={{ color: "#ff8989" }}>ถอนเงิน</span> 7
                    วันย้อนหลัง
                  </span>
                  <div className="containloophiswd">
                    {/* Loop หน้าถอนงิน -------------------------------------------------------------- */}
                    {/* <div
                      onClick={(e) => this.opentab(e, 'slip')}
                      // onClick="opentab(event, 'slip')"

                      className="historyofwd">
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td width="50%" style={{ paddingTop: 7 }}>
                              <table>
                                <tbody>
                                  <tr>
                                    <td style={{ paddingRight: 5 }}>
                                      {" "}
                                      <img
                                        className="backlogohis"
                                        src="/build/web/igame-index-lobby-wm/img//scb.svg"
                                      />
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      <span className="spanofbankhis">
                                        ธนาคารไทยพาณิชย์
                                      </span>
                                      <br />
                                      <span className="spanofbankhis">
                                        859-2-59209-0
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td width="50%" style={{ textAlign: "right" }}
                            >
                              <div className="statushistory">
                                {" "}
                                <span style={{ background: "#c98e15" }}>
                                  รอดำเนินการ
                                </span>
                              </div>
                              <span className="moneyhisdps">
                                {" "}
                                <i className="fal fa-minus-circle minushis" />{" "}
                                250,000.00 บาท
                              </span>
                              <br />
                              <span style={{ fontSize: 11 }}>15/02/2021 05:50:34</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> */}
                    {this.state.historylist.map((listitem, i) => (
                      listitem.type == "WD" || listitem.type == "CC" ?
                        <div className="historyofwd" >
                          <table width="100%">
                            <tbody>
                              <tr>
                                <td width="50%" style={{ paddingTop: 7 }}>
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td style={{ paddingRight: 5 }}>
                                          {" "}
                                          <img
                                            className="backlogohis"
                                            src="/build/web/igame-index-lobby-wm/img/scb.svg"
                                          />
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                          <span className="spanofbankhis">
                                            {(listitem.type === "BN") ? "*โบนัสพิเศษ" : (listitem.type === "DE") ? "ฝากเงิน" : "ถอนเงิน"}
                                          </span>
                                          <br />
                                          <span className="spanofbankhis">
                                            <span style={{ display: (listitem.status === "0") ? "" : "none", margin: "5px" }}>
                                              <a href={() => false} style={{ cursor: "pointer" }} onClick={(e) => {
                                                this.cancelwithdraw(e, listitem)
                                              }}
                                                className="-link-change-password" ><u>ยกเลิก</u></a>
                                            </span>
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td
                                  width="50%"
                                  style={{ textAlign: "right" }}
                                >
                                  <div >
                                    {" "}
                                    <span >
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "2") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <i className="fas fa-spinner fa-spin"></i>
                                        <span className="-state-title">กำลังดำเนินการ</span>
                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "0") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <i className="fas fa-spinner fa-spin"></i>
                                        <span className="-state-title">รอดำเนินการ</span>

                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "4") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <img
                                          src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                          className="-ic"
                                          width={15}
                                          alt=""
                                        />
                                        {" "}
                                        <span className="-state-title">ยกเลิก (ยกเลิกโดยผู้เล่น)</span>
                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "5") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <img
                                          src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                          className="-ic"
                                          width={15}
                                          alt=""
                                        />
                                        {" "}
                                        <span className="-state-title">ไม่สำเร็จ (โดยระบบ)</span>
                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "1") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <img
                                          src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                          className="-ic"
                                          width={15}
                                          alt=""
                                        />
                                        {" "}
                                        <span className="-state-title">สำเร็จ</span>
                                      </div>
                                    </span>
                                  </div>
                                  <span className="moneyhisdps">
                                    {" "}
                                    <i className="fal fa-plus-circle plushis" />   {" "}{listitem.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                    บาท
                                  </span>
                                  <br />
                                  <span style={{ fontSize: 11 }}>{moment(listitem.createdate).format('DD/MM/YY') + " - " + moment(listitem.createdate).format('HH:mm') + " น."} </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div> : ""
                    ))}

                    {/* End Loop หน้าถอนเงิน -------------------------------------------------------------- */}
                  </div>
                </div>
              </div>
            </div>
            {/* End history section */}
            {/* language section */}
            <div id="language" className="tabcontent" style={{ display: "none" }}>
              <div className="headerprocess">
                <i className="fal fa-language" /> ภาษา / Language
              </div>
              <div className="containprocess " style={{ marginTop: 10 }}>
                <table width="260px" align="center">
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center", width: "50%" }}>
                        <img src="/build/web/igame-index-lobby-wm/img/th.svg" width="90px" />
                        <br />
                        ไทย
                      </td>
                      {/* <td style={{ textAlign: "center", width: "50%" }}>
                        <img src="/build/web/igame-index-lobby-wm/img/language/en.svg" width="90px" />
                        <br />
                        English
                      </td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* End language section */}
            {/* playgame section */}
            <div id="playgame" className="tabcontent" style={{ display: "none" }}>
              <div className="headerprocess">
                <i className="fal fa-gamepad-alt" /> เข้าเล่นเกม
              </div>
              <div className="containprocess " style={{ paddingTop: 20 }}>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td className="gameingtd active">
                        <div
                          className="bkcolor tablinkgame active"
                          onClick={(e) => this.opengame(e, 'casino')}
                          id="dfgameOpen"
                        >
                          <span>
                            <i className="fal fa-spade" /> คาสิโน
                          </span>
                        </div>
                      </td>
                      <td className="gameingtd">
                        <div
                          className="bkcolor tablinkgame"
                          // onClick="opengame(event, 'slot')"
                          onClick={(e) => this.opengame(e, 'slot')}
                        >
                          <span>
                            <i className="fal fa-dice" /> สล็อต
                          </span>
                        </div>
                      </td>
                      <td className="gameingtd">
                        <div
                          className="bkcolor tablinkgame"
                          // onClick="opengame(event, 'game')"
                          onClick={(e) => this.opengame(e, 'game')}
                        >
                          <span>
                            <i className="fal fa-gamepad-alt" /> กีฬา
                          </span>
                        </div>
                      </td>
                      <td className="gameingtd">
                        <div
                          className="bkcolor tablinkgame"
                          // onClick="opengame(event, 'huay')"
                          onClick={(e) => this.opengame(e, 'huay')}
                        >
                          <span>
                            <i className="fal fa-sort-numeric-up-alt" /> ยิงปลา
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div id="casino" className="tabgame" style={{ display: "block" }}>
                  <div className="gridofgame">
                    {/* เกม Casino  */}
                    {this.state.casinolist.map((listitem, i) => (
                      <div className="ingridgame" onClick={(e) => this.opengamelist(e, 'gamelist', 'casino', listitem.partner)}>
                        <div className="iningridgame">
                          <img src={listitem.image} width={70} />
                          <br />
                          {listitem.description}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div id="slot" className="tabgame" style={{ display: "none" }}>
                  <div className="gridofgame" >
                    {this.state.slotlist.map((listitem, i) => (
                      <div className="ingridgame" onClick={(e) => this.opengamelist(e, 'gamelist', 'slot', listitem.partner)}>
                        <div className="iningridgame">
                          <img src={listitem.image} width={70} />
                          <br />
                          {listitem.description}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div id="game" className="tabgame" style={{ display: "none" }}>
                  <div className="gridofgame">
                    {this.state.sportlist.map((listitem, i) => (
                      <div className="ingridgame" onClick={(e) => this.opengamelist(e, 'gamelist', 'sport', listitem.partner)}>
                        <div className="iningridgame">
                          <img src={listitem.image} width={70} />
                          <br />
                          {listitem.description}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div id="huay" className="tabgame" style={{ display: "none" }}>
                  <div className="gridofgame">
                    {this.state.fishinglist.map((listitem, i) => (
                      <div className="ingridgame" onClick={(e) => this.opengamelist(e, 'gamelist', 'lotto', listitem.partner)}>
                        <div className="iningridgame">
                          <img src={listitem.image} width={70} />
                          <br />
                          {listitem.description}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div id="gamelist" className="listname" style={{ display: "block" }}>
                  <div className="gridofgame">
                    {/* เกม Casino  */}
                    {this.state.gamelistall.map((listitem, i) => (
                      <div className="ingridgame " onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}>
                        <div className="iningridgame">
                          <img src={listitem.image} width={70} />
                          <br />
                          {listitem.description}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/* End playgame section */}
            {/* friend section */}
            <div id="friend" className="tabcontent" style={{ display: "none" }}>
              <div className="containfriend">
                <div className="bgcutinfriend">
                  <div className="headerprocess">
                    <i className="fal fa-users" /> พันธมิตร
                  </div>
                  <div className="wrapgrid001">
                    <div className="inwrapgrid001">
                      <div
                        className="ininwrapgrid001 active"
                        onClick={(e) => this.openfriendtab(e, 'allfriend')}
                        id="tabfriendopen"
                      >
                        <i className="far fa-handshake" />
                        <br />
                        ภาพรวม
                      </div>
                    </div>
                    <div className="inwrapgrid001">
                      <div
                        className="ininwrapgrid001 "
                        //   onClick="openfriendtab(event, 'friendtabs')"
                        onClick={(e) => this.openfriendtab(e, 'friendtabs')}
                      >
                        <i className="far fa-handshake" />
                        <br />
                        เพื่อน
                      </div>
                    </div>
                    <div className="inwrapgrid001">
                      <div
                        className="ininwrapgrid001  "
                        //  onClick="openfriendtab(event, 'moneyfriendtabs')"
                        onClick={(e) => this.openfriendtab(e, 'moneyfriendtabs')}
                      >
                        <i className="far fa-handshake" />
                        <br />
                        รายได้
                      </div>
                    </div>
                    <div className="inwrapgrid001">
                      <div
                        className="ininwrapgrid001  "
                        // onClick="openfriendtab(event, 'withdrawfriendtabs')"
                        onClick={(e) => this.openfriendtab(e, 'withdrawfriendtabs')}
                      >
                        <i className="far fa-handshake" />
                        <br />
                        ถอนรายได้
                      </div>
                    </div>
                  </div>
                  <div className="moneycontainaf">
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td>
                            <i className="fas fa-users-crown" />
                          </td>
                          <td style={{ textAlign: "right" }}>
                            รายได้ปัจจุบัน / รับไปทั้งหมด <br />
                            <span style={{ fontSize: 25, color: "#fddc8c" }}>
                              {tblprofile.AffiliateCash}
                            </span>
                            <br />
                            <div style={{ position: "relative" }}>
                              <span className="countearnmoney">
                                <i className="fad fa-money-bill" />
                                อัตราส่วนแบ่งรายได้ (1 ชั้น)
                              </span>
                              {/* ปุ่มถอนรายได้ form>....... */}
                              <br />
                              <div id="withdrawfriend" style={{ display: "none" }}>
                                <button className="btn-grad" onClick={(e) => {
                                  this.opencliaminvite(e, 'bonusrankaccount')
                                }} type="submit">
                                  <i className="fas fa-hand-holding-usd" />{" "}
                                  แจ้งถอนรายได้
                                </button>
                              </div>
                              {/* </from>................ */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="pcfriendback">
                      <table style={{ width: "100%" }}>
                        <tbody>
                          <tr>
                            <td className="text-left">
                              <i className="fad fa-coins" style={{ fontSize: 20 }} />{" "}
                              <span>ส่วนแบ่งรายได้ชั้นที่ 1</span>
                            </td>
                            <td className="text-right">
                              <span style={{ fontSize: 20 }}>{tblprofile.AffPercent} %</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <table style={{ width: "100%" }}>
                        <tbody>
                          <tr>
                            <td className="text-left">
                              <i className="fad fa-coins" style={{ fontSize: 20 }} />{" "}
                              <span>ส่วนแบ่งรายได้ชั้นที่ 2</span>
                            </td>
                            <td className="text-right">
                              <span style={{ fontSize: 20 }}>5 %</span>
                            </td>
                          </tr>
                        </tbody>
                      </table> */}
                      <div className="containlinkcopy">
                        ลิงค์แนะนำ
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td width="80%" style={{ textAlign: "center" }}>
                                <input
                                  className="friendlink"
                                  id="friend"
                                  type="text"
                                  name="link"
                                  readOnly=""
                                  defaultValue={linkaff}
                                />
                              </td>
                              <td width="20%" style={{ textAlign: "right" }}>
                                <a
                                  href={() => false} style={{ cursor: "pointer" }}
                                  onClick={() => this.copyCode()}
                                  className="btnfriendback02"
                                >
                                  คัดลอกลิงค์
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    id="allfriend"
                    className="friendcontent"
                    style={{ display: "block" }}
                  >
                    <div
                      style={{ padding: "0 30px", paddingTop: 10, textAlign: "center" }}
                    >
                      <span className="detailaf">รายละเอียด</span>
                      <div role="alert" className="indetail">
                        <div className="row" style={{ paddingTop: 5 }}>
                          <div className="col-6 text-left">
                            <span>เพื่อนทั้งหมด</span>
                          </div>
                          <div className="col-4 text-right">0</div>
                          <div className="col-2">คน</div>
                        </div>
                        <div className="row" style={{ paddingTop: 5 }}>
                          <div className="col-6 text-left">
                            <span>เพื่อนที่ฝาก</span>
                          </div>
                          <div className="col-4 text-right">0</div>
                          <div className="col-2">คน</div>
                        </div>
                        <div className="row" style={{ paddingTop: 5 }}>
                          <div className="col-6 text-left">
                            <span>ยอดฝาก</span>
                          </div>
                          <div className="col-4 text-right">0.00</div>
                          <div className="col-2">บาท</div>
                        </div>
                        <div className="row" style={{ paddingTop: 5 }}>
                          <div className="col-6 text-left">
                            <span>ยอดแทงเสีย</span>
                          </div>
                          <div className="col-4 text-right">0.00</div>
                          <div className="col-2">C</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="alert01 mt-1"
                      role="alert01"
                      style={{ fontSize: 11, textAlign: "left", color: "#000" }}
                    >
                      <span style={{ fontSize: 20, color: "#e8c675" }}>
                        ระบบพันธมิตร
                      </span>
                      หารายได้กับเราง่ายๆเพียงแค่ท่านแชร์ลิ้งแนะนำเพื่อนออกไป
                      ท่านก็สามารถรับรายได้จากยอดแทงเสียของเพื่อนไปเลย
                    </div>
                  </div>
                  <div
                    id="friendtabs"
                    className="friendcontent"
                    style={{ display: "none" }}
                  >
                    <div
                      style={{
                        marginTop: 15,
                        position: "relative",
                        textAlign: "center"
                      }}
                    >
                      {" "}
                      <span className="detailaf">รายชื่อเพื่อน</span>
                    </div>
                    <div className="divoffriends"></div>
                  </div>
                  <div
                    id="moneyfriendtabs"
                    className="friendcontent"
                    style={{ display: "none" }}
                  >
                    <div style={{ padding: 10 }}>เลือกเดือน</div>
                    <div className="divoffriends">
                      <input
                        type="month"
                        id="month"
                        name="month"
                        className=""
                        defaultValue="2021-03"
                      />
                    </div>
                  </div>
                  <div
                    id="withdrawfriendtabs"
                    className="friendcontent"
                    style={{ display: "none" }}
                  >
                    <div className="wranningwd" style={{ padding: 10 }}>
                      <span className="detailaf">คำอธิบาย</span>
                      รายได้ที่เกิดขึ้นในวันนี้จะสามารถทำการถอนได้ในวันถัดไป
                    </div>
                    <div className="divoffriends"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* End friend section */}
            {/* comis section */}
            <div id="comis" className="tabcontent" style={{ display: "none" }}>
              <div className="headerprocess">
                <i className="fal fa-hands-usd" /> คืนยอดเสีย
              </div>
              <div className="containprocess" style={{ padding: "0 20px" }}>
                <div className="moneyfriend">
                  <div style={{ fontSize: 15, color: "#fff" }}>ยอดทั้งหมด</div>
                  <div style={{ marginTop: 5, fontWeight: "bold" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="cashbmoney">฿ {tblprofile.Cashtotal} </td>
                          <td style={{ width: "50%", textAlign: "right" }}>
                            <button className="btncashback02 mcolor" onClick={(e) => {
                              this.opencliamcashout(e, 'bonusrankaccount')
                            }}>ถอนเงิน</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="pccashback">
                    <table width="100%" style={{ whiteSpace: "nowrap" }}>
                      <tbody>
                        <tr>
                          <td style={{ width: "33.3%", textAlign: "center" }}>
                            อาทิตย์นี้ <br />{" "}
                            <span style={{ color: "#fff", fontSize: 14 }}>
                              ฿ {tblprofile.Cashtotal}
                            </span>
                          </td>
                          <td style={{ width: "33.3%", textAlign: "center" }}>
                            ที่สามารถรับได้ <br />{" "}
                            <span style={{ color: "#fff", fontSize: 14 }}>
                              ฿ {tblprofile.Cashtotal}
                            </span>
                          </td>
                          <td style={{ width: "33.3%", textAlign: "center" }}>
                            จ่ายแล้ว <br />{" "}
                            <span style={{ color: "#fff", fontSize: 14 }}>
                              ฿ 0.00
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
                <div
                  className="alert01 mt-1"
                  role="alert01"
                  style={{ fontSize: 11, textAlign: "left", color: "#000" }}
                >
                  <span style={{ fontSize: 20, color: "#e8c675" }}>
                    ระบบคืนยอดเสีย
                  </span>
                  คำนวณทุกวันอาทิตย์ เวลา 00.30น. ไม่เกิน 02.30 ขอสงวนสิทธ์ในการคิดยอดของเว็บเป้นที่สิ้นสุด
                </div>
              </div>
            </div>
            {/* End comis section */}
            {/* event section */}
            <div id="event" className="tabcontent" style={{ display: "none" }}>
              <div className="headerprocess">
                <i className="fal fa-calendar-star" /> กิจกรรม
              </div>
              <div className="containprocess" style={{ padding: "0 20px" }}>
                <div className="containprocess containevent">
                  <div className="swiper-container-3 swiper-coverflow swiper-3d swiper-initialized swiper-horizontal swiper-watch-progress">
                    <div
                      className="swiper-wrapper"
                      id="swiper-wrapper-3d27de4c4948557f"
                      aria-live="polite"
                      style={{
                        cursor: "grab",
                        transitionDuration: "0ms",
                        transitionDelay: "0ms"
                      }}
                    >
                      <div
                        className="swiper-slide"
                        style={{ backgroundImage: 'url("images/promotion/1.jpg")' }}
                      >
                        <div className="detailpro">
                          <i className="fad fa-circle-notch" /> Commission Return
                          คืนค่าคอมทุกยอดฝากสูงสุด 2.3% <br />
                          <i className="fad fa-circle-notch" /> Commission Return
                          คืนค่าคอมทุกยอดฝากสูงสุด 2.3% <br />
                          <i className="fad fa-circle-notch" /> ไม่ต้องทำเทิร์น
                          <br />
                          <div style={{ textAlign: "center" }}>
                            <button className="btnbonus mcolor">รับโบนัส</button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide"
                        style={{ backgroundImage: 'url("images/promotion/2.jpg")' }}
                      >
                        <div className="detailpro">
                          <i className="fad fa-circle-notch" /> สมัครใหม่ รับโบนัสฟรี
                          30% <br />
                          <i className="fad fa-circle-notch" /> สมัครใหม่ รับโบนัสฟรี
                          30% <br />
                          <i className="fad fa-circle-notch" /> ฟรีสูงสุด 2,000 เครดิต
                          ถอนไม่อั้น
                          <br />
                          <div style={{ textAlign: "center" }}>
                            <button className="btnbonus mcolor">รับโบนัส</button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide"
                        style={{ backgroundImage: 'url("images/promotion/4.jpg")' }}
                      >
                        <div className="detailpro">
                          <i className="fad fa-circle-notch" /> รับโบนัสจุใจตลอดทั้งวัน
                          10% ถอนไม่อั้น <br />
                          <i className="fad fa-circle-notch" /> รับโบนัสจุใจตลอดทั้งวัน
                          10% ถอนไม่อั้น <br />
                          <i className="fad fa-circle-notch" /> ทำเทิร์น 1 เท่า
                          <br />
                          <div style={{ textAlign: "center" }}>
                            <button className="btnbonus mcolor">รับโบนัส</button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide"
                        style={{ backgroundImage: 'url("images/promotion/3.jpg")' }}
                      >
                        <div className="detailpro">
                          <i className="fad fa-circle-notch" /> ไม่รับโบนัส <br />
                          <i className="fad fa-circle-notch" /> ไม่รับโบนัส <br />
                          <i className="fad fa-circle-notch" />{" "}
                          ได้เสียเท่าไหร่ถอนได้ทันที
                          <br />
                          <div style={{ textAlign: "center" }}>
                            <button className="btnbonus mcolor">รับโบนัส</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Add Pagination */}
                    <span
                      className="swiper-notification"
                      aria-live="assertive"
                      aria-atomic="true"
                    />
                  </div>
                  <button
                    className="btnnext2 bkcolor"
                    tabIndex={0}
                    aria-label="Next slide"
                    aria-controls="swiper-wrapper-3d27de4c4948557f"
                    aria-disabled="false"
                  >
                    <i className="fad fa-chevron-right" />
                  </button>
                  <button
                    className="btnprev2 bkcolor"
                    tabIndex={0}
                    aria-label="Previous slide"
                    aria-controls="swiper-wrapper-3d27de4c4948557f"
                    aria-disabled="false"
                  >
                    <i className="fad fa-chevron-left" />
                  </button>
                </div>
              </div>
            </div>
            {/* End event section */}
            {/* slip section */}
            <div id="slip" className="tabcontent" style={{ display: "none" }}>
              <div className="headerprocess">
                <i className="fal fa-minus-circle" style={{ color: "#dd7b7b" }} />{" "}
                ประวัติการถอนเงิน
              </div>
              <div className="containprocess" style={{ padding: "0 20px" }}>
                <div className="slipimage">
                  <div style={{ paddingBottom: 10 }}>
                    <img src="/build/web/igame-index-lobby-wm/img/logotext.png" height="70px" />
                  </div>
                  <div className="infoslip">
                    <table align="center" style={{ marginBottom: 10 }}>
                      <tbody>
                        <tr>
                          <td style={{ padding: "0 5px" }}>
                            <i
                              className="fas fa-check-circle"
                              style={{ color: "green", fontSize: 40 }}
                            />
                          </td>
                          <td style={{ textAlign: "left" }}>
                            <span
                              style={{
                                color: "green",
                                fontSize: 20,
                                fontWeight: "bold"
                              }}
                            >
                              ถอนเงินสำเร็จ
                            </span>
                            <br />
                            <div style={{ lineHeight: 17 }}>
                              <span style={{ color: "black" }}>13 กพ 64 12:12 น.</span>
                              <br />{" "}
                              <span style={{ color: "black", fontSize: 12 }}>
                                เลขที่รายการ: 654065
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr
                      style={{
                        borderTop: "1px solid #f2cf1a",
                        margin: "3px 0",
                        width: "100%"
                      }}
                    />
                    <table align="center" style={{ color: "black", fontSize: 20 }}>
                      <tbody>
                        <tr>
                          <td style={{ height: 20, verticalAlign: "top" }}>
                            จำนวน:{" "}
                            <span style={{ fontWeight: "bold" }}>80,000.00 บาท </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr
                      style={{
                        borderTop: "1px solid #f2cf1a",
                        margin: 2,
                        width: "100%"
                      }}
                    />
                    <table align="center" style={{ color: "black", width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            <table>
                              <tbody>
                                <tr>
                                  <td style={{ padding: "10px 0px" }}>
                                    <img src="/build/web/igame-index-lobby-wm/img//scb.png" width="30px" />
                                  </td>
                                  <td style={{ padding: "0 10px" }}>
                                    <i className="fas fa-arrow-right" />
                                  </td>
                                  <td>
                                    <img src="/build/web/igame-index-lobby-wm/img//scb.png" width="30px" />
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 10,
                                      padding: "0 2px",
                                      fontWeight: "bold"
                                    }}
                                  >
                                    SCB
                                    <br />
                                    XXX-X-XXXX8-X
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    borderTop: "1px solid #f2cf1a",
                                    borderBottom: "1px solid #f2cf1a",
                                    margin: 10,
                                    width: "100%",
                                    fontWeight: "bold"
                                  }}
                                >
                                  <td colSpan={4} style={{ padding: "10px 0px" }}>
                                    USER :ARCXXXXX99999
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td style={{ padding: 8, lineHeight: 9 }}>
                            <img style={{ width: 90 }} />
                            <br />
                            <span style={{ fontSize: 12 }}>verified by Archa168</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* End slip section */}
            {/* slip section */}
            <div id="account" className="tabcontent" style={{ display: "none" }}>
              <div className="headerprocess">
                <i className="fal fa-user" /> ข้อมูลส่วนตัว
              </div>
              <div className="containprocess bgback" style={{ padding: "0 20px" }}>
                <table align="center" className="accountofuser">
                  <tbody>
                    <tr className="trofaccount">
                      <td className="headeraccount">
                        <i className="fal fa-user" /> คุณ:
                      </td>
                      <td>{fullname}</td>
                    </tr>
                    <tr className="trofaccount">
                      <td className="headeraccount">
                        <i className="fal fa-user-circle" /> ยูสเซอร์:
                      </td>
                      <td>{username}</td>
                    </tr>
                    <tr className="trofaccount">
                      <td className="headeraccount">
                        <i className="fal fa-lock" /> รหัสผ่าน:
                      </td>
                      <td>******</td>
                    </tr>
                    <tr className="trofaccount">
                      <td className="headeraccount">
                        <i className="fal fa-university" /> ธนาคารของคุณ:
                      </td>
                      <td>
                        <img src={accnumber !== null ? accnumberx.img_path : ""} width="25px" /> {accnumber !== null ? accnumberx.fullname_th : ""}
                      </td>
                    </tr>
                    <tr className="trofaccount">
                      <td className="headeraccount">
                        <i className="fal fa-gift" /> โปรโมชั่นของคุณ:
                      </td>
                      <td>ไม่รับโปรโมชั่น</td>
                    </tr>
                    <tr className="trofaccount">
                      <td className="headeraccount">
                        <i className="fal fa-users" /> ยอดแนะนำเพื่อน:
                      </td>
                      <td>0 บาท</td>
                    </tr>
                    <tr className="trofaccount">
                      <td className="headeraccount">
                        <i className="fal fa-sack-dollar" /> ยอดคืนยอดเสีย:
                      </td>
                      <td>0 บาท</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* End slip section */}
          </div>
        </div>

      </>
    );
  }
}

export default App;
